export const settingItemsTab = [
  {
    key: 'cartsTable',
    tab: 0,
    string: 'tables_or_carts',
    description: 'tables_or_carts_desc'
  },
  {
    key: 'cartAlwaysOpen',
    tab: 0,
    string: 'cart_always_open',
    description: 'cart_always_open_desc'
  },
  {
    key: 'showProductImage',
    tab: 0,
    string: 'show_product_image',
    description: 'show_product_image_desc'
  },
  {
    key: 'cartLevelDiscount',
    tab: 0,
    string: 'allow_cart_discounts',
    description: 'allow_cart_discounts_desc'
  },
  {
    key: 'productLevelDiscount',
    tab: 0,
    string: 'allow_product_discounts',
    description: 'allow_product_discounts_desc'
  },
  {
    key: 'disableCustomDiscounts',
    tab: 0,
    string: 'disable_custom_discounts',
    description: 'disable_custom_discounts_desc'
  },
  {
    key: 'enableCartItemRemove',
    tab: 0,
    string: 'enable_cart_item_remove',
    description: 'enable_cart_item_remove_desc'
  },
  {
    key: 'showCalculator',
    tab: 0,
    string: 'show_calculator',
    description: 'show_calculator_desc'
  },
  {
    key: 'showRepsWaste',
    tab: 0,
    string: 'show_reps_waste',
    description: 'show_reps_waste_desc'
  },
  {
    key: 'dropShip',
    tab: 0,
    string: 'enable_drop_ship',
    description: 'enable_drop_ship_desc'
  },
  {
    key: 'showCartsListAlphabeticOrder',
    tab: 0,
    string: 'show_carts_list_alphabetic',
    description: 'show_carts_list_alphabetic_desc'
  },
  {
    key: 'barcodeCustomer',
    tab: 0,
    string: 'barcode_customer',
    description: 'barcode_customer_desc'
  },
  {
    key: 'allowVoucherScan',
    tab: 0,
    string: 'allow_voucher_scan',
    description: 'allow_voucher_scan_desc'
  },
  {
    key: 'allowTicketScan',
    tab: 0,
    string: 'allow_ticket_scan',
    description: 'allow_ticket_scan_desc'
  },
  {
    key: 'askCoversTakeaway',
    tab: 0,
    string: 'ask_no_covers_or_takeaway',
    description: 'ask_no_covers_or_takeaway_desc'
  },
  {
    key: 'showItemStatus',
    tab: 0,
    string: 'show_item_status',
    description: 'show_item_status_desc'
  },
  {
    key: 'listStatusSquares',
    tab: 0,
    string: 'list_status_squares',
    description: 'list_status_squares_desc'
  },
  {
    key: 'askBuzzerInfo',
    tab: 0,
    string: 'ask_buzzer_info',
    description: 'ask_buzzer_info_desc'
  },
  {
    key: 'showStock',
    tab: 1,
    string: 'show_stock_level',
    description: 'show_stock_level_desc'
  },
  {
    key: 'playSoundSuccessScan',
    tab: 1,
    string: 'play_sound_success_scan',
    description: 'play_sound_success_scan_desc'
  },
  {
    key: 'showProductImageList',
    tab: 1,
    string: 'show_product_image_list',
    description: 'show_product_image_list_desc'
  },
  {
    key: 'productTileSize',
    tab: 1,
    string: 'product_tile_size',
    description: 'product_tile_size_desc'
  },
  {
    key: 'hideCategoriesTile',
    tab: 1,
    string: 'hide_categories',
    description: 'hide_categories_desc'
  },
  {
    key: 'categoriesAlphabeticOrder',
    tab: 1,
    string: 'categories_alphabetic_order',
    description: 'categories_alphabetic_order_desc'
  },
  {
    key: 'productsAlphabeticOrder',
    tab: 1,
    string: 'products_alphabetic_order',
    description: 'products_alphabetic_order_desc'
  },
  {
    key: 'allowProductExchanges',
    tab: 1,
    string: 'allow_product_exchanges',
    description: 'allow_product_exchanges_desc'
  },
  {
    key: 'allowProductRefunds',
    tab: 1,
    string: 'allow_product_refunds',
    description: 'allow_product_refunds_desc'
  },
  {
    key: 'enableDepositButton',
    tab: 1,
    string: 'enable_deposit_button',
    description: 'enable_deposit_button_desc'
  },
  {
    key: 'searchProductBarcodeServer',
    tab: 1,
    string: 'search_product_barcode_server',
    description: 'search_product_barcode_server_desc'
  },

  {
    key: 'skipTransactionModal',
    tab: 2,
    string: 'skip_payment_success_info',
    description: 'skip_payment_success_info_desc'
  },
  {
    key: 'allowPartialPayment',
    tab: 2,
    string: 'allow_partial_payment',
    description: 'allow_partial_payment_desc'
  },
  {
    key: 'allowNegativePayments',
    tab: 2,
    string: 'allow_negative_payments',
    description: 'allow_negative_payments_desc'
  },
  {
    key: 'showFullReceiptAfterPayment',
    tab: 2,
    string: 'show_full_receipt_after_payment',
    description: 'show_full_receipt_after_payment_desc'
  },
  {
    key: 'showCartNameInTransaction',
    tab: 2,
    string: 'show_cart_name_transaction',
    description: 'show_cart_name_transaction_desc'
  },
  {
    key: 'refundWithSameMethod',
    tab: 2,
    string: 'refund_same_method',
    description: 'refund_same_method_desc'
  },
  {
    key: 'enableCustomerScreen',
    tab: 2,
    string: 'enable_customer_screen',
    description: 'enable_customer_screen_desc',
    desktopOnly: true
  },
  {
    key: 'floorplanOnNewSale',
    tab: 2,
    string: 'show_floorplan_after_sale',
    description: 'show_floorplan_after_sale_desc'
  },
  {
    key: 'enableGiftcardScan',
    tab: 2,
    string: 'enable_giftcard_scan',
    description: 'enable_giftcard_scan_desc'
  },
  {
    key: 'askExtraNonRevenueInfo',
    tab: 2,
    string: 'extra_non_revenue_info',
    description: 'extra_non_revenue_info'
  },
  {
    key: 'showSuggestions',
    tab: 2,
    string: 'show_suggestion_amounts',
    description: 'show_suggestion_amounts_desc'
  },
  {
    key: 'roundCashPayment',
    tab: 2,
    string: 'round_cash_transactions',
    description: 'round_cash_transactions_desc'
  },
  {
    key: 'askTips',
    tab: 2,
    string: 'ask_tips',
    description: 'ask_tips'
  },
  {
    key: 'kioskMode',
    tab: 3,
    string: 'kiosk_mode',
    description: 'kiosk_mode_desc'
  },
  {
    key: 'adminPin',
    tab: 3,
    string: 'admin_pin',
    description: 'admin_pin_desc'
  },
  {
    key: 'employeePin',
    tab: 3,
    string: 'employee_pin',
    description: 'employee_pin_desc'
  },
  {
    key: 'sharedShift',
    tab: 3,
    string: 'shared_shift',
    description: 'shared_shift_desc'
  },
  {
    key: 'adminOnlyRefunds',
    tab: 3,
    string: 'admin_only_refunds',
    description: 'admin_only_refunds_desc'
  },
  {
    key: 'adminOnlyCustomItem',
    tab: 3,
    string: 'admin_only_custom_item',
    description: 'admin_only_custom_item_desc'
  },
  {
    key: 'employeeLock',
    tab: 3,
    string: 'employee_lock',
    description: 'employee_lock_desc'
  },
  {
    key: 'cashDrawerAdminOnly',
    tab: 3,
    string: 'cash_drawer_admin_only',
    description: 'cash_drawer_admin_only_desc'
  },
  {
    key: 'openCloseShift',
    tab: 3,
    string: 'open_close_shift',
    description: 'open_close_shift_desc'
  },
  {
    key: 'reportsStoreLevel',
    tab: 3,
    string: 'reports_at_store_level',
    description: 'reports_at_store_level_desc'
  },
  {
    key: 'language',
    tab: 3,
    string: 'language',
    description: 'language_desc'
  },
  {
    key: 'theme',
    tab: 3,
    string: 'theme',
    description: 'theme_desc'
  },

  {
    key: 'kitchenPrint',
    tab: 4,
    string: 'kitchen_print',
    description: 'kitchen_print_desc',
    desktopOnly: true
  },
  {
    key: 'kitchenDeliveryPrint',
    tab: 4,
    string: 'kitchen_delivery_print',
    description: 'kitchen_delivery_print_desc',
    desktopOnly: true
  },
  {
    key: 'disablePrintRemovedItems',
    tab: 4,
    string: 'disable_print_removed_items',
    description: 'disable_print_removed_items_desc',
    desktopOnly: true
  },
  {
    key: 'showOrderBill',
    tab: 4,
    string: 'show_order_bill',
    description: 'show_order_bill_desc',
    desktopOnly: true
  },
  {
    key: 'splitOrderItems',
    tab: 4,
    string: 'split_orders_items',
    description: 'split_orders_items_desc',
    desktopOnly: true
  },
  {
    key: 'splitOrderAmount',
    tab: 4,
    string: 'split_orders_amount',
    description: 'split_orders_amount_desc',
    desktopOnly: true
  },
  {
    key: 'allowPartialPaymentPrint',
    tab: 4,
    string: 'partial_payment_print',
    description: 'partial_payment_print_desc',
    desktopOnly: true
  },
  {
    key: 'showEANSKUReceipt',
    tab: 4,
    string: 'show_ean_sku_receipt',
    description: 'show_ean_sku_receipt_desc'
  },
  {
    key: 'showBrandReceipt',
    tab: 4,
    string: 'print_brand',
    description: 'print_brand'
  },
  {
    key: 'printQR',
    tab: 4,
    string: 'print_qr',
    description: 'print_qr_desc',
    desktopOnly: true
  },
  {
    key: 'printTickets',
    tab: 4,
    string: 'print_tickets',
    description: 'print_tickets_desc',
    desktopOnly: true
  },
  {
    key: 'printTicketsOnReceiptPrinter',
    tab: 4,
    string: 'print_tickets_receipt',
    description: 'print_tickets_receipt_desc',
    desktopOnly: true
  },
  {
    key: 'qrCodeForLineItems',
    tab: 4,
    string: 'qr_codes_for_line_items',
    description: 'qr_codes_for_line_items',
    desktopOnly: true
  },
  {
    key: 'printDescription',
    tab: 4,
    string: 'print_description',
    description: 'print_description',
    desktopOnly: true
  },
  {
    key: 'a4Print',
    tab: 4,
    string: 'a4_paper',
    description: 'a4_paper_desc'
  },
  {
    key: 'printOrdersForMultipleStores',
    tab: 5,
    string: 'receive_online_orders_for_multiple_stores',
    description: 'receive_online_orders_for_multiple_stores'
  },
  // {
  //   key: 'shareCartAllStores',
  //   tab: 5,
  //   string: 'share_cart_all_stores',
  //   description: 'share_cart_all_stores'
  // },
  {
    key: 'keepSingleCart',
    tab: 5,
    string: 'keep_single_cart',
    description: 'keep_single_cart'
  },
  {
    key: 'crashDrawerPartialPayment',
    tab: 4,
    string: 'cashdrawer_partial_payment',
    description: 'print_qr_desc',
    desktopOnly: true
  },
  {
    key: 'printCardInfo',
    tab: 4,
    string: 'print_card_info',
    description: 'print_card_info'
  }
]
