import store from '../index'

const ADMIN_ONLY = [
  'change_device_name',
  'reports',
  'payment_method',
  'settings',
  'printer_settings',
  'recover_carts',
  'cds_settings',
  'go_dashboard',
  'sign_out',
  'open_cashdrawer'
]

// Get Start day or false
export const getStartDay = id => {
  if (store.getState().settings.sharedShift) {
    return getLocalStorageKeys('_startDay')
  }

  return localStorage.getItem(`${id}_startDay`) || false
}

// Get End day or false
export const getEndDay = id => {
  if (store.getState().settings.sharedShift) {
    return getLocalStorageKeys('_endDay')
  }

  return localStorage.getItem(`${id}_endDay`) || false
}

export const createOperationObj = (
  { employeeId, devices },
  currentDate,
  amount,
  action,
  reason,
  tickets = 0,
  currency
) => {
  return {
    employee: employeeId,
    date: currentDate,
    action: action,
    cash: amount,
    note: reason,
    tickets,
    currency,
    store: devices.store._id,
    device: devices.device._id
  }
}

/**
 * Evaluate If Start or End day button should be enabled
 * If localStorage not filled return False
 * If filled return the Date saved
 *
 * Returning a object with boolean operator
 * for disabled button attribute
 *
 * @param {String || Boolean} haveStartDay
 * @param {String || Boolean} haveEndDay
 *
 * @returns {Object} state
 */
export const evaluateCurrentShiftState = (haveStartDay, haveEndDay) => {
  const state = {
    haveStartDay: !!haveStartDay,
    haveEndDay: !!haveEndDay
  }

  if (haveStartDay && haveEndDay) {
    state.haveEndDay = true
  } else if (!haveStartDay && !haveEndDay) {
    state.haveEndDay = true
    state.haveStartDay = false
  }

  return state
}

/**
 * Return if employee has the rights to access this feature
 *
 * @param {Object} employee
 * @param {String} featureName
 *
 * @returns {Boolean} Employee can/cannot access feature
 */
export const employeeHasRights = (employee, featureName) => {
  if (featureName === 'open_cashdrawer') {
    if (!store.getState().settings.cashDrawerAdminOnly) {
      return true
    }
  }

  if (!!Object.keys(employee).length) {
    return (employee.rights === 'user' || employee.rights === 'none') &&
      ADMIN_ONLY.indexOf(featureName) >= 0
      ? false
      : true
  }

  return true
}

/**
 * Return local stoarge keys including paramString
 *
 * @param {String} paramString
 *
 * @returns {Array} Local storage key value
 */
export const getLocalStorageKeys = paramString => {
  let keyToUse = ''
  const allKeys = Object.keys(localStorage)

  if (paramString === '_startDay') {
    keyToUse = allKeys.find(k => k.includes(paramString) && !k.includes('Amount'))
  } else {
    keyToUse = allKeys.find(k => k.includes(paramString))
  }

  return localStorage.getItem(`${keyToUse}`) || false
}

/**
 * Removes _startDay and _startDayAmount in case sharedShift setting is enabled
 */
export const sharedShiftClearStorage = () => {
  const allKeys = Object.keys(localStorage)

  const keysToRemove = allKeys.filter(k => k.includes('_startDay') || k.includes('_startDayAmount'))

  keysToRemove.forEach(key => {
    localStorage.removeItem(`${key}`)
  })
}
