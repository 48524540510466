/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const nlLang = {
  student: 'leerling',
  id_check_alert: "Klant: {{customer}}. Controleer de volgende id's van klanten: {{id}}.",
  id_check: 'ID-controle',
  cart: 'Bestelling',
  item: 'Artikel',
  items: 'Artikelen',
  subtotal: 'Subtotaal',
  discount: 'Korting',
  total: 'Totaal',
  pay: 'Betalen',
  card: 'Pin',
  cash: 'Contant',
  invoice: 'Factuur',
  giftcard: 'Cadeaukaart',
  countr_giftcard: 'Cadeaukaart Countr',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Betalingswijze',
  cash_received: 'Bedrag ontvangen',
  or_enter_amount: 'Of valuta toevoegen',
  payment_succeeded: 'Betaling geslaagd',
  change_due: 'Wisselgeld',
  total_paid: 'Totaal betaald',
  send_receipt: 'Bon verzenden',
  send_invoice: 'Factuur verzenden',
  send: 'Versturen',
  delete: 'Verwijderen',
  new_sale: 'Nieuwe verkoop',
  custom_price: 'Aangepaste prijs',
  item_description: 'Product beschrijving',
  products: 'Producten',
  transactions: 'Transacties',
  reports: 'Rapporten',
  report: 'Rapportage',
  settings: 'Instellingen',
  sign_out: 'Afmelden',
  receipt: 'Bon',
  refund: 'Retour',
  home: 'Home',
  clear: 'Wissen',
  add: 'Toevoegen',
  sign_up: 'Aanmelden',
  sign_up_email: 'Registreren met email',
  sign_in: 'Inloggen...',
  company_name: 'Bedrijfsnaam',
  merchant_name: 'handelaar_naam',
  email_address: 'E-mailadres',
  phone_number: 'Telefoonnummer',
  password: 'Wachtwoord',
  confirm_password: 'Bevestig wachtwoord',
  forgot_password_question: 'Wachtwoord vergeten?',
  next: 'Volgende',
  demo: 'Demo',
  skip: 'Overslaan',
  skip_registration: 'Registratie overslaan',
  take_a_tour: 'Neem een rondleiding',
  items_returned: 'Artikelen terug',
  wrong_billing: 'Fout aangeslagen',
  order_cancelled: 'Bestelling gecancelled',
  other: 'Andere pin apparaat',
  enter_valid_amount: 'Vul het juiste bedrag in',
  invalid_merchant_name: 'Contact persoon ongeldig',
  invalid_business_name: 'Ongeldige bedrijfsnaam',
  invalid_email_address: 'Ongeldig e-mailadres',
  invalid_phone_number: 'Ongeldig telefoonnummer',
  invalid_password: 'Ongeldig wachtwoord',
  password_mismatch: 'Wachtwoorden komen niet overeen',
  coming_soon: 'Komt binnenkort',
  to_use_signup: 'Om deze functie te gebruiken, moet u zich aanmelden',
  login_error: 'Login fout',
  signup_error: 'Registratie mislukt',
  transaction_history_empty: 'Transactie geschiedenis leeg',
  categories: 'Categorieen',
  all_products: 'Alle producten',
  search_product: 'Zoek product',
  reissue_receipt: 'Print bon opnieuw',
  print_receipt: 'Print bon',
  or_send_receipt: 'Of verstuur bon',
  save: 'Opslaan',
  saved: 'Opgeslagen',
  search: 'Zoeken',
  custom_item: 'Custom item',
  submit: 'Indienen',
  submitted: 'Ingediend',
  note: 'Notitie',
  message: 'Bericht',
  amount: 'Bedrag',
  number: 'Nummer',
  taxes: 'BTW',
  tax: 'TVA/BTW',
  enter_valid_number: 'Vul een geldig aantal in',
  enter_valid_text: 'Vul een geldige tekst in',
  enter_valid_phone: 'Vul een geldig telefoonnummer in',
  enter_valid_email: 'Vul een geldig emailadres in',
  feature_not_in_demo: 'Functionaliteit niet beschikbaar in de demo-modus',
  provide_refund_reason: 'Restitutie reden verplicht',
  provide_valid_refund_amount: 'Restitutie bedrag verplicht',
  refund_exceed_refundable:
    'Restitutie bedrag hoger dan het maximale te betalen voor deze transactie',
  refund_saved: 'Restitutie opgeslagen',
  or_customize_discount: 'Of aangepaste korting',
  enter_percentage: 'Invoeren percentage',
  manual_card_payment: 'Handmatig pinpas betaling',
  did_card_payment_succeed: 'Betaling gelukt?',
  manual_card_refund: 'Handmatig PIN/Credit card restitutie',
  did_card_refund_succeed: 'Restitutie gelukt?',
  giftcard_refund: 'Cadeaukaart restitutie',
  did_giftcard_refund_succeed: 'Cadeaukaart uitgegeven?',
  yes: 'Ja',
  no: 'Nee',
  general: 'Algemeen',
  printer: 'Printer',
  barcode_scanner: 'Barcode scanner',
  payment: 'Betalingen',
  profile: 'Profiel',
  currency: 'Valuta',
  printing_receipt: 'Afdrukken...',
  enter_amount: 'Bedrag',
  expected_change: 'Wisselgeld',
  still_remaining: 'Nog te betalen',
  issue_invoice: 'Factuur',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Adres',
  address_continued: 'Adres 2 [Optioneel}}',
  city: 'Plaatsnaam',
  zip: 'Postcode',
  country: 'Land',
  country_iso: 'Land: ISO 3166-2 formaat ("nl", "de", "gb")',
  invalid_address: 'Adres leeg / ongeldig',
  invalid_city: 'Platsnaam leeg / ongeldig',
  invalid_country: 'Land leeg / ongeldig',
  invalid_ecommerce: 'Geen e-commerce platform gekozen',
  signup_error_contact_support: 'Storing met signup - neem a.u.b. contact met support@countrhq.com',
  open_orders: 'Openstaande bestellingen',
  order: 'Bestelling',
  enter_weight_g: 'Voer het gewicht (g) in',
  search_scanner: 'Socket CHS Scanner zoeken',
  order_settings: 'Bestelling instellingen',
  order_amount: 'Aantal openstaande bestellingen beschikbaar',
  receipts: 'Bonnen',
  print_receipts: 'Bon automatisch afdrukken',
  open_cash_drawer: 'Kassalade automatisch openen',
  backup: 'Backup',
  email_backup: 'Backup emailen',
  server_sync: 'Server Sync',
  external_payment: 'Andere pin apparaat',
  search_printer: 'Printer zoeken',
  sms_successfully_sent: 'SMS successvol verzonden',
  error_sending_sms: 'SMS storing, probeer later opnieuw',
  email_successfully_sent: 'Email successvol verzonden',
  error_sending_email: 'Email storing, probeer later opnieuw',
  transaction: 'Transactie',
  logging_in: 'Inloggen...',
  downloading_products: 'Artikelen downloaden...',
  add_to_cart: 'Toevoegen',
  invalid_price: 'Ongeldige prijs',
  invalid_discount: 'Ongeldige korting',
  invalid_new_price: 'Ongeldige nieuwe prijs - moet lager dan originele prijs',
  invalid_amount: 'Ongeldig bedraag',
  edit_price: 'Prijs bewerken',
  discount_perc: 'Korting (%)',
  quantity: 'Aantal',
  remove: 'Verwijderen',
  sales_info: 'Verkoopinformatie',
  summary: 'Overzicht',
  refunded_information: 'Restitutie informatie',
  total_sale: 'Totale Verkoop',
  total_amount_refunded: 'Gerestitueerd',
  remaining: 'Resterende',
  sold_by: 'Verkocht door',
  source_receipt_number: 'Bron bonnummer',
  issued_date: 'Uitgifte datum',
  issued_time: 'Uitgifte tijd',
  refunded_by: 'Gerestitueerd door',
  reason: 'Reden',
  receipt_number: 'Bonnummer',
  refunded_date: 'Gerestitueerd datum',
  refunded_time: 'Gerestitueerd tijd',
  first_name: 'Voornaam',
  last_name: 'Achternaam',
  shop_category: 'Locatie categorie',
  choose_country: 'Land selecteren...',
  you_will_receive_email: 'Gefeliciteerd, registratie voltooid',
  wait_email:
    'U ontvangt nu een email met verder instructies over hoe u uw weblocatie met Countr kunt verbinden',
  open_dashboard:
    'U kunt nu uw account beheren en producten toevoegen in uw persoonlijk Countr dashboard',
  by_signing_up: 'Door in te schrijven ga ik akkoord met de',
  terms_and_conditions: 'Algemene Voorwaarden',
  and: 'en',
  privacy_policy: 'Privacybeleid',
  refund_items: 'Artikelen terugbetalen',
  forgot_password: 'Wachtwoord vergeten?',
  register_device: 'Apparaat registreren',
  device_not_registered: 'Apparaat niet geregistreerd',
  want_to_add_to_account: 'Nu het registreren?',
  if_ios_previously_registered:
    'Heeft u onlangs de Countr POS app opnieuw geinstalleerd? Registreer dan uw apparaat opnieuw',
  new_device_info: 'U kunt uw apparaat in de volgende stap met (een van) uw locatie(s) verbinden',
  not_now: 'Niet nu',
  proceed: 'Verder',
  more_info_click: 'Meer informatie? Klik hier',
  your_detail: 'Uw gegevens',
  vat_no: 'BTW nummer',
  billing_details: 'Factuurgegevens',
  add_store: 'Locatie selecteren',
  or_add_to_existing_store: 'Of nieuwe locatie maken',
  add_new_store: 'Locatie toevoegen',
  store_information: 'Locatie informatie',
  store_name: 'Locatienaam',
  go_to_web_dashboard: 'Naar web dashboard',
  error_create_account: 'Foutmelding aanmaken account',
  error_creation_error: 'Registratie fout',
  error_wrong_phone_number: 'Voor een geldig telefoonnummer in',
  error_try_again: 'Verbindingsfout. Probeer later opnieuw',
  error_from_the_server: 'Verbindingsfout. Probeer later opnieuw',
  error_update_cc_info: 'Kaart informatie kan niet worden bijgewerkt.',
  error_add_device: 'Apparaat kan niet worden toegevoegd.',
  error_add_store: 'Locatie kan niet worden toegevoegd.',
  are_not_valid: 'zijn ongeldig',
  is_not_valid: 'is ongeldig',
  are_empty: 'zijn leeg',
  is_empty: 'is leeg',
  are_not_selected: 'zijn niet geselecteerd',
  is_not_selected: 'is niet geselecteerd',
  bill: 'Rekening',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Sluiten',
  no_product_found: 'Geen artikelen gevonden',
  please_go_to_dashboard: 'Ga naar https://backoffice.countrhq.com',
  select_interface: 'Selecteer interface',
  refund_all: 'Alles',
  purchased_items: 'Gekochte items',
  refundable: 'Restitueerbaar',
  to_be_refunded: 'Te restitueren',
  out_of: 'Op',
  enter_a_printer_name: 'Printer naam',
  cart_is_empty: 'Bestelling is leeg',
  searching_printers: 'Zoeken naar printers...',
  no_printers_found: 'Geen printers gevonden.',
  total_due: 'Te betalen',
  payment_not_successful: 'Betaling mislukt',
  error_processing_payment: 'Fout met betaling',
  purchase: 'Aankoop',
  transaction_aborted: 'Transactie afgebroken',
  authorization_error: 'Authorisatie fout',
  authorization_ok: 'Authorisatie gelukt',
  transaction_cancelled: 'Transactie afgebroken',
  clearance_error: 'Clearance error by processor',
  clearance_ok: 'Clearance confirmed OK by processor',
  transaction_declined: 'Transactie geweigerd',
  transaction_complete: 'Transactie voltooid',
  initiating: 'Initieren',
  application_selected: 'Applicatie geselecteerd',
  scheduled_for_clearance: 'Scheduled for off-line clearance by processor',
  terminal_error: 'Betaalautomaat foutmelding',
  unknown_status: 'Onbekende status',
  waiting_for_clearance: 'Waiting for off-line clearance confirmation by processor',
  amount_exceeded: 'Maximale bedraag overschreden',
  authorization_timeout: 'Authorisatie timeout',
  batch_processing_failed: 'Batch processing failed',
  cancelled: 'Afgebroken',
  card_blocked: 'Kaart geblokkeerd',
  card_invalid: 'Kaart ongeldig',
  card_declined: 'Kaart geweigerd',
  transaction_failed: 'Transactie niet gelukt',
  insufficient_funds: 'Geen saldo',
  terminal_not_paired: 'Betaalautomaat niet gekoppeld',
  pin_incorrect_last_retry: 'Foute PINcode - laatste poging',
  pin_incorrect_retry: 'Foute PINcode - probeer opnieuw',
  printer_busy: 'Printer in gebruik',
  printer_error: 'Printer fout',
  printer_not_present: 'Geen printer',
  printer_out_of_paper: 'Geen papier',
  success: 'Succes',
  system_error: 'Systeemfout',
  transaction_not_found: 'Transactie niet gevonden',
  unknown_device: 'Onbekende apparaat',
  apply_discount: 'Geef korting',
  seqr_id: 'SEQR Merchant ID',
  seqr_password: 'SEQR Merchant Password',
  bitpay_api_token: 'Bitpay API Token',
  registration_successful: 'Registratie successvol',
  unregistration_successful: 'Deregistratie successvol',
  payment_init_not_successful: 'Payment not initialised - check network connection.',
  always_show_addons: 'Addons altijd laten zien',
  show_product_image: 'Productbeeld altijd laten zien',
  hide_product_img: 'Productafbeelding verbergen',
  settings_connected_printers: 'Aangesloten printers',
  settings_add_new_printer: 'Voeg een nieuwe printer',
  wifi_lan: 'WiFi / LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Aangesloten barcode scanners',
  account: 'Account',
  refresh_products: 'Vernieuwen producten',
  server_synchronisation: 'Server-synchronisatie',
  sales: 'Sales',
  today: 'Vandaag',
  refunds: 'Restituties',
  product: 'Product',
  product_by_category: 'Producten per categorie',
  see_categories_details: 'Zie gegevens per categorie',
  open_orders_finish_payment_or_empty: 'Er zijn open orders',
  name: 'Naam',
  loading_products: 'Laden van producten',
  you_should_select_at_least_one_item: 'U moet tenminste een item selecteren',
  select_card_provider: 'Selecteer een leverancier voor betalingen',
  user_cancelled: 'Betaling geannuleerd door klant',
  card_payment: 'Betaling per kaart',
  manage_products: 'Beheer producten',
  edit_mode: 'Bewerkmodus',
  lock: 'Vergrendel',
  loyalty: 'Loyaliteit',
  account_card: 'Account kaart',
  account_lookup: 'Account zoeken',
  Activate: 'Activeer',
  add_anonymous_customer: 'Voeg anonieme klant toe',
  add_customer: 'Klant toevoegen',
  add_customer_to_cart: 'Toevoegen aan bestelling',
  add_new_account: 'Nieuw account toevoegen',
  add_new_customer: 'Nieuwe klant toevoegen',
  add_note_hint: 'Melding toevoegen',
  add_tax_to_price: 'Prijzen exclusief BTW',
  add_tip: 'Fooi toevoegen',
  add_to_store: 'Locatie toevoegen',
  addons: 'Addons',
  admin_pin: 'Admin PIN',
  admin_pin_saved: 'Nieuwe admin PIN bewaard',
  admin_pin_should_be: 'Admin PIN moet tussen de 4 en 6 cijfers zijn',
  aid: 'AID',
  aidCard: 'aidCard',
  allow_cart_discounts: 'bestelling korting toestaan',
  allow_custom_cart_discounts: 'Custom bestelling korting toestaan',
  allow_custom_items: 'Custom items toestaan',
  allow_product_discounts: 'Product korting toestaan',
  allow_custom_product_discounts: 'Custom product korting toestaan',
  amount_hint: '999',
  app_version: 'Versie',
  applicationLabel: 'ApplicatieLabel',
  applicationName: 'ApplicatieNaam',
  applicationPreferredName: 'ApplicatieVoorkeursNaam',
  appName: 'appNaam',
  ask_tips: 'Fooi bedrag suggereren',
  authorizationCode: 'AuthorisatieCode',
  available_points: 'Beschikbare punten',
  available_rewards: 'Beschikbare beloningen',
  back: 'Terug',
  barcode_scanner_disconnected:
    'De scanner is verbonden, maar staat misschien uit. Zet de scanner opnieuw aan',
  barcode_scanner_not_found: 'Geen Barcode Scanner gevonden',
  barcode_scanner_not_found_desc:
    'Een nieuwe scanner verbinden - Zet bluetooth aan in de apparaat instellingen en zoek hier beneden naar de scanner',
  bunq: 'bunq',
  bunq_admin_approval: 'Een bunq beheerder zal uw kassa beoordelen en goedkeuren voor gebruik',
  bunq_instructions:
    "1) Open de bunq app en voer uw PIN in (indien nodig)\n2) Kies 'Scan QR code' of 'QR' op het hoofdscherm\n3) Scan de code op het scherm\n4) Voeg fooi toe indien gewenst\n5) Bevestig betaling",
  can_only_refund_entire_amount:
    'Het is alleen mogelijk om het totale bedrag te restitueren via {{payment_method}}',
  cancel: 'Annuleren',
  cancel_payment: 'Annuleer betaling',
  cannot_pay_later_more_than_remaining_value:
    'U kunt niet meer betalen dan het resterende bedrag ({{amount}}) in het geval van pay later (betaal later)',
  cannot_refund_transaction_with_partial_postponed_payments:
    'Deze transactie bevat een of meerdere uitgestelde betalingen. Maak eerst de transactie af of restitueer het volledige bedrag',
  cant_find_products:
    'Kunt u producten niet vinden in de POS? Gebruik dan de Zoek functie bovenin of een barcodescanner.',
  card_scheme: 'Kaartsysteem',
  cardBrand: 'kaartMerk',
  cardExpiryMonth: 'Kaart vervaldatum maand MM',
  cardExpiryYear: 'Kaart  vervaldatum jaar JJ',
  cardType: 'kaartType',
  cart_is_frozen_finish_payment:
    'Dit bestelling is bevroren. Voltooi de betaling om verder te gaan.',
  cash_difference: 'Verschil',
  cash_drawer: 'Kassalade',
  cash_in: 'Contant geld in',
  cash_in_out: 'Contant geld in/uit',
  cash_out: 'Contant geld uit',
  change: 'Wisselgeld',
  change_currency: 'Verander valuta',
  change_given: 'Wisselgeld teruggegeven',
  change_open_orders_amount: 'Verander Open Orders aantal',
  check_location_settings: 'Check locatie instellingen',
  check_network: 'Check uw netwerk verbinding',
  choose_category: 'Kies een bedrijf categorie',
  choose_mobile_provider: 'Meerdere providers geactiveerd - Selecteer er een',
  clear_settings: 'Wis instellingen',
  clear_settings_successful: 'Instellingen succesvol verwijderd',
  clear_settings_warning:
    'Deze instellingen verwijderen zorgt voor een reset van uw verbinding informatie. Kies bevestig om verder te gaan.',
  client_ticket: 'klantTicket',
  close_shift: 'Sluit kassa',
  company_address: 'Zakelijk adres',
  company_registration_no: 'KVK Nummer',
  completed_payments: 'Voltooide betalingen',
  confirm: 'Bevestig',
  confirm_cash_payment: 'Bevestig bedrag cash betaling',
  confirm_new_currency: 'Bevestig valuta verandering',
  confirm_open_orders_amount_change: 'Bevestig aanpassing openstaande orders?',
  confirm_payment: 'Bevestig betaling',
  confirm_with_change: 'Bevestig met wisselgeld',
  confirm_with_tip: 'Bevestig met fooi',
  connect_barcode: 'Koppel barcodescanner (Socket CHS)',
  connect_to_webshop: 'De app aan uw weblocatie koppelen?',
  connected_printer: 'Verbonden printers',
  contact_information: 'Contact informatie',
  contact_us: 'support@countrhq.com',
  create_product: 'Maak product',
  currently_trial_mode_transaction_wiped_visit_dashboard:
    'De POS is momenteel in test-modus. Uw transacties worden iedere 4 uur gewist.',
  currently_trial_mode_visit_dashboard:
    'De POS is momenteel in test-modus. Schakel naar de live-modus in uw online dashboard',
  customer: 'Klant',
  customer_card: 'Klant kaart',
  customer_info: 'Klant info',
  customer_instructions: 'Klanten instructies',
  customer_lookup: 'Klant opzoeken',
  customer_lookup_error: 'Server fout tijdens het opzoeken van klant',
  customer_name: 'Klant',
  customer_not_valid: 'Klant niet geldig',
  customer_receipt: 'Klanten Bon',
  customer_since: 'Klant sinds',
  Date: 'Datum',
  deregister_terminal: 'Verwijder Terminal',
  description_mandatory:
    'Een beschrijving is verplicht voor contant geld in en contant geld uit transacties',
  device_locked: 'Apparaat vergrendeld',
  device_locked_enter_pin: 'Dit apparaat is vergrendeld.\nVoer uw admin PIN in om te ontgrendelen',
  did_cheque_refund_succeed: 'Cheque terug gegeven aan klant?',
  did_mobile_payment_succeed: 'Mobiele betaling geslaagd?',
  did_mobile_refund_succeed: 'Mobiele restitutie geslaagd?',
  direct_connection: 'Rechtstreeks verbonden',
  disabled_product: 'Uitgeschakeld product',
  discount_currency: 'Korting ({{currency}})',
  do_you_want_remove_payment: 'Betaling verwijderen?',
  download_adyen_app: 'Download de Adyen App',
  download_izettle_app: 'Download de iZettle App',
  download_payleven_app: 'Download de Payleven App',
  download_sumup_app: 'Download de SumUp App',
  download_yodo_app: 'Download Yodo Rocket app?',
  downloading_categories: 'Categorieen downloaden...',
  downloading_taxes: 'BTW downloaden...',
  downloading_transactions: 'Transacties downloaden....',
  ecommerce_none: 'Zelf producten aanmaken',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Email factuur',
  employee_name: 'Werknemer',
  employee_pin: 'Werknemer PIN',
  empty_cart: 'bestelling leegmaken',
  enable_staff_pin: 'Werknemer PIN login inschakelen',
  enter_a_valid_search_value: 'Kies een geldige zoek term',
  enter_api_key: 'Activatie sleutel invoeren',
  enter_base_url: 'Voer uw base URL in',
  enter_merchant_auth_code_payconiq: 'Voer Payconiq Machtiging header in',
  enter_merchant_iban_payconiq: 'Voer Payconiq-gereristreerde IBAN in',
  enter_merchant_id_payconiq: 'Voer Payconiq Merchant ID in',
  enter_order_name: 'Order naam invoeren',
  enter_reseller_id: 'Voer uw Reseller ID in',
  enter_user_number: 'Voer uw User Number in',
  enter_user_password: 'Voer uw User Password in',
  error_adding_device: 'Fout tijdens het registreren van uw apparaat',
  error_refreshing_merchant: 'Fout tijdens het opvragen van account informatie',
  error_retrieve_categories:
    'Fout bij het downloaden van de categorieen. Check uw internet verbinding en probeer het nogmaals',
  error_retrieve_products:
    'Fout tijdens het opvragen van uw producten.\nNeem contact op met support@countrhq.com',
  error_retrieve_taxes:
    'Fout bij het downloaden van de BTW. Check uw internet verbinding en probeer het nogmaals',
  error_while_retrieving_results: 'Fout tijdens het opvragen van de resultaten',
  errorCode: 'foutmeldingCode',
  errorMessage: 'foutmelding',
  ersReference: 'ersReferentie',
  excess_amount: 'Overschot bedrag',
  expected_amount: 'Verwachte bedrag',
  expected_change_gratuity: 'Verwachte wisselgeld of fooi',
  external_url: 'Externe URL',
  failed_cancel: 'Transactie annuleren niet gelukt',
  feature_only_android: 'Deze functie is alleen beschikbaar voor Android',
  find_terminal: 'Zoek terminals',
  for_security_enter_password: 'Voer wegens veiligheidsoverwegingen uw wachtwoord nogmaals in.',
  for_this_device: 'Alleen voor dit apparaat',
  for_this_store: 'Alleen voor deze locatie',
  four_inch: '4 inch',
  generate_keys: 'Genereer sleutels',
  generating_keypair: 'Sleutelpaar genereren...\nDit kan enkele minuten duren',
  generating_reports: 'Rapport genereren...',
  generic_usb: 'Algemene USB',
  get_your_free_pos: 'Probeer het gratis kassasysteem op www.countrhq.com',
  gift_code: 'Cadeau code',
  gratuity_amount: 'Fooi Bedrag',
  gratuity_percentage: 'Fooi Percentage',
  group_items_in_cart: 'Bundel dezelfde items in bestelling',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions:
    'Verversen niet mogelijk.\n {{transaction_number}} transactie(s) nog niet gesynchroniseerd met de server.\nZorg dat u een internet verbinding heeft, wacht tot transacties gesynchroniseerd zijn en probeer opnieuw.',
  impossible_signout_you_have_transactions:
    'Uitloggen niet mogelijk.\n {{transaction_number}} transactie(s) nog niet gesynchroniseerd met de server.\nZorg dat u een internet verbinding heeft, wacht tot transacties gesynchroniseerd zijn en probeer opnieuw.',
  insert_printer_name: 'Geef printer een naam (tenminste 3 tekens)',
  inserted_login_not_valid: 'Ingevoerde login is niet juist',
  inserted_value_not_valid: 'Ingevoerde waarde is niet geldig',
  installmentAmount: 'TermijnBedrag',
  invalid_credit_card_number: 'Credit card nummer moet 16 cijfers hebben',
  invoiceReference: 'FactuurReferentie',
  invoiceStatus: 'FactuurStatus',
  ip_address: 'IP adres',
  is_business: 'Bedrijfsgegevens',
  is_correct_amount_tendered: 'Is {{amount}} het correcte bedrag?',
  isDone: 'Niets meer om weer te geven...',
  isError: 'Tap om nogmaals te proberen...',
  isTap: 'Tap om meer te laden...',
  join_thousands_of_merchants:
    'Duizenden ondernemers sloten zich al aan bij Countr. Maak een gratis account aan',
  language: 'Taal',
  last_visit: 'Laatste bezoek',
  leave_tour: 'Verlaat tour',
  leaving_tour: 'Tour aflsuiten...',
  loyal_customer: 'Loyale klant',
  mac_address: 'MAC adres',
  mailing_list_permission: 'Toevoegen aan mailing lijst',
  manual_mobile_payment: 'Mobiele betaling',
  manual_mobile_refund: 'Mobiele restitutie',
  max_amount_exceeded:
    'Bedrag overschrijdt het maximale bedrag toegestaan voor deze betalingsmethode',
  menu_item_12char: 'Menu item naam kan niet langer dan 12 tekens zijn',
  menu_item_name: 'Menu item naam (max. 12 tekens)',
  merchant_receipt: 'Ondernemer Bon',
  merchantDisplayName: 'MerchantWeergaveNaam',
  merchantId: 'MerchantID',
  mid: 'MID',
  mobile_number: 'Telefoonnummer',
  mobile_payment: 'Mobiel',
  mobile_payments: 'Mobiele betalingen',
  new_price: 'Nieuwe prijs',
  no_available_addons_for_product: 'Geen addons beschikbaar voor dit product',
  no_connected_printer: 'Geen verbonden printer(s)',
  no_connected_terminal: 'Geen verbonden terminal(s)',
  no_customer_found: 'Geen klant gevonden',
  no_customer_selected: 'NVT',
  no_printer_configured: 'Geen printer geconfigureerd',
  no_printer_found: 'Geen printer gevonden\nKlik hier voor de USB-verbonden Star printer',
  no_product_in_cart: 'Geen producten in bestelling',
  no_terminals_found: 'Geen terminals gevonden',
  no_url_specified: 'Foutmelding, geen UL gespecificeerd',
  none: 'Geen',
  note_mandatory_cash_difference:
    'Een notitie is verplicht wanneer verwachte en werkelijke bedragen niet overeen komen',
  note_optional: 'Aantekening (Optioneel)',
  numberOfInstallments: 'AantalTermijnen',
  numpad: 'Numpad',
  offline: 'Offline',
  ok: 'Oke',
  one_or_more_carts_are_frozen_finish_payment:
    'Een of meerdere bestellingen zijn bevroren. Voltooi de betaling om verder te gaan',
  online: 'Online',
  open_market: 'App location openen?',
  open_register_before_transaction: 'Open eerst de kassa voordat transacties kunnen worden gedaan',
  open_shift: 'Open kassa',
  order_printer: 'Bestelling printer',
  orderId: 'BestellingID',
  orderRef: 'BestellingRef',
  other_text: 'Andere Tekst',
  paid: 'Betaald',
  paid_so_far: 'Betaald tot nu toe',
  paid_with_method: 'Betaald met {{method}}',
  pan: 'PAN',
  panLastDigits: 'PAN laatste cijfers',
  panMasked: 'Pan Gemaskeerd',
  PANSeq: 'PANSeq',
  partial_payment: 'Gedeeltelijke betaling',
  partial_payment_not_allowed_for: 'Gedeeltelijke betalingen zijn niet toegestaan voor',
  partial_payment_paid_successfully: 'Gedeeltelijke betaling geslaagd',
  pay_later: 'Betaal Later',
  pay_later_allows_only_refund_all:
    'Met Betaal Later kunt u alleen het volledige bedrag restitueren. Rond de betaling eerst af voor gedeeltelijke restituties.',
  pay_later_refund: 'Annuleer Betaal Later',
  pay_on_account: 'Betaal op account',
  payconiq: 'Payconiq',
  paying_now: 'Betaal nu',
  payment_cancelled: 'Betaling geannuleerd',
  payment_error: 'Betaling fout',
  payment_info: 'Betaal Informatie',
  payment_insert_card: 'Kaart invoeren',
  payment_method_not_configured: '{{payment_method}} niet geconfigureerd op dit apparaat',
  payment_number: 'Betaling Nummer',
  payment_processor_options: 'Payment processor opties',
  payment_provider: 'Betalingsaanbieder',
  payment_success: 'Betaling geslaagd',
  payment_terms_in_net_days: 'Betaling termijnen (netto dagen)',
  personal_information: 'Persoonlijke informatie',
  pickup_rewards: 'Beloningen ophalen',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Voer een geldig bedrag in',
  posEntryMode: 'posEntryMode',
  prefName: 'PrefNaam',
  print: 'Print',
  print_also_product_details: 'Ook product details printen?',
  print_error: 'Print foutmelding',
  print_product_details: 'Print product details',
  print_successful: 'Print succes',
  printer_connect_desc:
    'WiFi printers moeten op het zelfde netwerk zijn aangesloten als het apparaat. \nBluetooth printers moeten verbonden zijn via de Systeem instellingen voordat ze hier beschikbaar zijn',
  printer_removed: 'Printer verwijderd',
  printer_test: 'Printer test',
  printing_report: 'Print rapport...',
  proceed_with_next_payment: 'Doorgaan met volgende betaling.',
  processorName: 'ProcessorNaam',
  product_is_disable_continue_anyway: 'Dit product is uitgeschakeld. Wilt u het toch toevoegen?',
  product_sync: 'Product Sync',
  profile_average: 'Gemiddelde aankoop',
  protected_sections: 'Beveiligde secties',
  receipt_printer: 'Bon printer',
  redeem_reward: 'Verzilver beloning',
  referenceNumber: 'ReferentieNummer',
  refresh_customers: 'Ververs klanten lijst',
  refresh_merchant: 'Ververs account informatie',
  refresh_transactions: 'Ververs transacties',
  refreshing_merchant: 'Account informatie verversen....',
  refund_number: 'Restitutie Nummer',
  refunds_not_available_for_provider:
    'Restituties niet beschikbaar voor betaaldienst: {{payment_provider}}',
  register_terminal: 'Registreer Terminal',
  reissue: 'Heruitgave',
  remaining_payment: 'Overgebleven bedrag',
  remove_customer_from_cart: 'Verwijder uit bestelling',
  remove_payment: 'Verwijder betaling',
  remove_printer: 'Verwijder printer',
  removing_products: 'Producten verversen...',
  reopen_shift: 'Her-open kassa',
  replace_customer: 'Vervang klant',
  report_by_shift: 'Dagelijks open en sluiten activeren',
  report_printer: 'Rapport printer',
  requested_amount: 'Aangevraagd bedrag',
  reward: 'Beloningen',
  round_cash_transactions: 'Contant afronden naar dichtstbijzijnde 0.05ct',
  save_printer: 'Bewaar Printer',
  scan: 'Scan',
  scan_barcode_instruction_one:
    'Scan de streepjescode om de Socket Bluetooth in koppel modus te brengen',
  scan_barcode_instruction_two:
    'Zorg dat bluetooth aan staat in de settings van uw apparaat en koppel dan de Socket SHC scanner',
  scan_code_not_exist: 'Gescande code {{barcode}} niet herkend',
  scan_qr_code: 'Scan de QR code',
  scanner: 'Scanner',
  scanner_connected: 'Barcodescanner gekoppeld',
  scanner_disconnected: 'Barcodescanner losgekoppeld',
  scanner_pair_completed: 'Barcodescanner koppelen gelukt',
  search_for_customer: 'Zoek naar klant',
  search_report: 'Zoek rapporten',
  search_transaction: 'Zoek naar transactie',
  searching_terminals: 'Zoeken naar terminal(s)....',
  security_and_permissions: 'Beveiliging en permissies',
  select_one_payment_method: 'Activeer ten minste een betaal methode',
  select_payment_method: 'Selecteer kaart AID uit de lijst',
  select_terminal: 'Selecteer een terminal',
  send_email: 'Stuur email',
  send_to_another_customer: 'Stuur naar andere klant',
  send_to_current_customer: 'Stuur naar huidige klant',
  send_to_customer: 'Stuur naar klant',
  sending_receipt: 'Bon versturen....',
  server_error: 'Server foutmelding',
  session: 'Sessie',
  set_new_price: 'Nieuwe prijs',
  setting_saved: 'Setting opgeslagen',
  settings_connected_terminals: 'Verbonden terminal',
  settings_printer_available_printers: 'Beschikbare printers',
  settlementDate: 'SettlementDatum',
  setup_admin_pin_in_settings: 'Stel een admin PIN in onder uw Account tab in Instellingen.',
  shift_closed: 'De kassa is gesloten',
  shift_opened: 'De kassa is geopend',
  shifts: 'Shifts',
  shopper_reference: 'ShopperReferentie',
  show_customer: 'Laat klant info zien op de bon',
  show_employee: 'Laat werknemer info zien op de bon',
  show_customer_payconiq: 'Kies een klant die heeft ingecheckt of scan de barcode/NFC-token',
  show_new_product_tile: "Toon 'maak product' veld",
  show_password: 'Toon mijn wachtwoord',
  sign_out_header: 'Weet u het zeker?',
  sign_out_warning:
    'Uitloggen leidt tot een reset van alle instellingen, waaronder betalingsinstellingen en printers. Kies cancel, installeer een Admin Pin en gebruik de Vergrendel functie als u uw instellingen wilt behouden. Kies bevestig om toch uit te loggen.',
  sign_up_to_print:
    'Meld je aan voor een gratis test account om een bon te printen. Geen credit card nodig, geen verplichtingen.',
  sign_up_to_send_email:
    'Meld je aan voor een gratis test account om een email bon te sturen. Geen credit card nodig, geen verplichtingen.',
  signing_out: 'Uitloggen...',
  signup_for_mailing_list: 'Vraag klanten om aanmelding mailing lijst',
  sorry_report_not_available: 'Dit rapport is nu niet beschikbaar, bekijk het in uw dashboard',
  start_using_pos: 'Start uw locatie met Countr',
  state: 'Provincie',
  status: 'Status',
  store_powered_by: 'Deze locatie maakt gebruik van',
  street: 'Straat',
  suggested_amount: 'Suggestie bedrag',
  suggested_tip: 'Suggestie fooi',
  support: 'Help + Support',
  telephone: 'Telefoon',
  terminal_port: 'Poort die is geconfigureerd voor de terminal',
  terminal_selected: 'Terminal opgeslagen',
  terminalId: 'TerminalId',
  test: 'Dit is een test',
  three_inch: '3 inch',
  tid: 'TID',
  Time: 'Tijd',
  tip: 'Fooi',
  tip_added: 'Fooi toegevoegd',
  to_pay: 'Te Betalen',
  total_amount: 'Totaal Bedrag',
  transaction_code: 'transactieCode',
  transaction_date: 'TransactieDatum',
  transaction_finalized: 'Transactie afgerond',
  transaction_pending: 'Wachten op transactie',
  transaction_started: 'Transactie begonnen',
  transaction_succeeded: 'Transactie geslaagd',
  transaction_time: 'Transactie tijd',
  transaction_timeout: 'Transactie time-out',
  transactionSeq: 'transactieReeks',
  transactionType: 'transactieType',
  try_it_now: 'Probeer het nu',
  tsi: 'TSI',
  tvr: 'TVR',
  two_inch: '2 inch',
  uncategorized: 'Geen categorie',
  valid_phone_number: 'Geldig telefoonnummer',
  validating_fields: 'Valideren..',
  validation_in_progress: 'Wachten op validatie',
  verification_code: 'Verificatie code',
  verification_method: 'Verificatie Methode',
  waiting_for_customer: 'Wachten op klant',
  want_to_sync_products: 'Producten nu synchroniseren?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Selecteer een locatie',
  add_name_device: 'Kies een naam voor dit tablet/telefoon (verplicht)',
  card_number: 'KaartNummer',
  change_language: 'Wijzig taal',
  choose_currency: 'Kies belangrijkste valuta ',
  clear_cart_after_finish: 'Wis bestelling/tafel naam na betaling',
  confirm_new_language:
    'Bevestig nieuwe taal?\nSluit en herstart de app volledig om deze verandering weer te geven',
  customer_signature: 'Handtekening klant',
  day: 'Dag',
  device_name_mandatory: 'Apparaat naam is verplicht',
  downloading_refunds: 'Refunds downloaden…',
  email_report: 'Email rapport ',
  email_successfully_sent_test: 'Er is een test email naar uw account verzonden ',
  enter_table_name: 'Voer tafel naam in',
  error: 'Error',
  error_retrieve_refunds: 'Error tijdens het downloaden van de refunds',
  error_retrieve_transactions: 'Error tijdens het downloaden van de transacties',
  for_this_device_see_dashboard:
    'Alleen voor dit apparaat - Kijk in het Dashboard voor meer rapporten',
  hide_new_sale: "Verberg 'Nieuwe Verkoop' scherm na betalingen",
  last_transaction_result: 'Meest recente transactie status',
  merge_warning: 'Dit voegt de inhoud van de bestelling samen',
  month: 'Maand',
  post_code: 'Postcode ',
  print_order: 'Stuur naar order printer weergeven',
  products_added_removed_refresh: 'Er zijn producten toegevoegd of verwijderd - lijst verversen? ',
  quarter: 'Kwartaal',
  refresh: 'Ververs',
  refreshing_products: 'Producten verversen…',
  removing_categories: 'Oude categorieën verwijderen… ',
  removing_taxes: 'Oude BTW verwijderen…',
  select_interface_mandatory: 'Een interface kiezen is verplicht ',
  sms_successfully_sent_test: 'Er is een test SMS naar uw account verzonden',
  splitOrderPrintByCategory: 'Splits het printen van bestellingen op categorie',
  stored_digitally: 'Digitaal opgeslagen',
  stripe_id: 'stripeID',
  sync_support_info: 'Synchroniseer support info',
  syncing_support_info: 'Support info synchroniseren…',
  table: 'Tafel',
  table_is_empty: 'Tafel is leeg',
  tables_or_carts: 'HoReCa-modus',
  uncertain_card_payment_success: 'Onbekend of kaart betaling succesvol was',
  unknown_category: 'Onbekende categorie',
  unsynced_transactions_report_incorrect:
    '{{transaction_number}} transactie(s) niet gesynchroniseerd\nRapporten zullen hierdoor niet de meest actuele status weergeven',
  update_terminal_firmware: 'Update terminal firmware',
  week: 'Week',
  year: 'Jaar',
  email_queued: 'Email in behandeling',
  order_received: 'Nieuwe / bijgewerkte order ontvangen',
  go_to_orders: 'Bekijk bestellingen',
  open_carts: 'Open bestellingen',
  auto_print_order: 'Print niet-geprinte bestellingen op betaalmoment',
  auto_print_order_cart: 'Print niet-geprinte bestellingen op bestelling/tafelwissel',
  external: 'Andere pin apparaat',
  no_printer_found_tcp: 'Printer niet gevonden \nKlik hier om handmatig printerinfo in te voeren',
  downloading_carts: 'Carts downloaden...',
  pay_later_hybrid: 'Betaal Later',
  error_retrieve_carts: 'Error tijdens het downloaden van de carts',
  no_ip_address: 'Geen adres',
  building_number: 'Huis nummer',
  confirm_manual_payment: 'Bevestig handmatig betaling',
  shipping_address: 'Afleveringsadres',
  birth_date: 'Geboortedatum',
  for_this_store_see_dashboard: 'Alleen voor deze locatie - zie Dashboard voor meer rapporten',
  customers: 'Klanten',
  tickets_in: 'Tickets binnen',
  tickets_out: 'Tickets uit',
  tickets_in_out: 'Tickets in / uit',
  unsynced_transaction_cant_refund:
    'Transactie nog niet gesynchroniseerd met server - restitutie niet mogelijk',
  show_stock_level: 'Huidige voorraadniveau weergeven',
  available: 'Op voorraad',
  stock_not_available: 'Voorraad niet beschikbaar',
  order_another_store: 'Bestellen vanuit een andere locatie',
  order_products: 'Schakel het bestellen van producten van andere locaties in',
  trial_expired: 'Uw proefperiode is verlopen. POS-functionaliteit is uitgeschakeld.',
  left_menu: 'Linker navigatiebalk',
  right_menu: 'Rechter navigatiebalk',
  direct_pay_if_one_method: 'Start de betaling automatisch als 1 methode is ingesteld',
  processing_vouchers: 'Loyaliteit en vouchers in behandeling...',
  display_2_rows_of_categories: 'Geef 2 rijen met categorieën weer',
  unknown_barcodes_as_vouchers: 'Behandel niet-herkende streepjescodes als vouchers',
  validate_signature: 'Handtekening klant geldig?',
  group_items_in_receipt_and_cart: 'Groepeer dezelfde items in bestelling en bon',
  ask_customer_subscribe_email: 'Vraag de klant om een e-mailadres op te geven',
  hide_categories: 'Verberg categorieën tegels',
  hide_all_products_category: 'Verberg de categorietegel "Alle producten"',
  provisional_receipt: '*** TIJDELIJK ***',
  reprint: '*** HERDRUK ***',
  block_reprints: 'Herdrukken van artikelen niet toegestaan',
  reports_at_store_level: 'Toon rapporten voor locatie, niet voor apparaat',
  max_addon_qty_exceeded: 'Maximaal {{maxQty}} add-ons toegestaan voor deze groep',
  min_addon_qty_unmet: 'Minimaal {{minQty}} add-ons vereist van {{groupName}}',
  press_button_to_wake_terminal: 'Druk op de groene knop om de PIN-terminal te activeren',
  payments: 'Betalingen',
  average_sale_duration_mins: 'Gemiddelde verkoopduur (min.)',
  save_order_move_next: 'Bewaar bestelling, ga door naar de volgende lege tafel',
  max_orders_reached: 'Maximaal aantal openstaande orders bereikt',
  show_open_orders: 'Toon openstaande bestellingen eerst in de bestellijst',
  tender_created: 'Betaling gestart',
  invalid_request: 'Onjuiste data',
  already_processed: 'Transactie al verwerkt',
  invalid_terminal: 'Ongeldige betaalterminal',
  network_error: 'Netwerkfout',
  bad_credentials: 'Ongeldige inloggegevens',
  server_issue: 'Kan geen contact maken met de server',
  pin_entered: 'PIN ingevoerd',
  initial: 'Initialiseren',
  card_inserted: 'Kaart geplaatst',
  card_swiped: 'Kaart gelezen',
  wait_app_selection: 'Wachten op selectie van applicatie',
  wait_amount_adjustent: 'Wachten op aanpassing van het bedrag',
  ask_gratuity: 'Vraag om fooi',
  gratuity_entered: 'Fooi ingevoerd',
  ask_dcc: 'Vraag om DCC',
  dcc_accepted: 'DCC geaccepteerd',
  dcc_rejected: 'DCC afgewezen',
  processing_tender: 'Verwerking tender',
  wait_pin: 'Wachten op PIN',
  provide_card_details: 'Geef kaartgegevens op',
  card_details_provided: 'Kaartgegevens verstrekt',
  receipt_printed: 'Bon afgedrukt',
  referral: 'Referral',
  referral_checked: 'Referral gecontroleerd',
  check_signature: 'Controleer handtekening',
  signature_checked: 'Handtekening gecontroleerd',
  additional_data_available: 'Extra gegevens beschikbaar',
  transaction_acknowledged: 'Transactie bevestigd',
  device_already_registered: 'Terminal al geregistreerd',
  add_in_progress: 'Al actief',
  app_not_registered: 'App niet geregistreerd',
  unknown_error: 'Onbekende fout',
  timeout: 'Time-out',
  verify_failed: 'Kan terminal niet verifiëren',
  identify_failed: 'Kan terminal niet identificeren',
  registration_error: 'Registratie fout',
  sync_issue: 'Kan terminali niet synchroniseren',
  show_select_customer: 'Klant selectie weergeven',
  store_name_mandatory: 'Locatie naam is verplicht',
  choose_store_dashboard_for_more:
    'Kies locatie - er kan meer worden toegevoegd via het backoffice-dashboard',
  recovering_incomplete_payment: 'Herstel onvolledige betaling - kan tot 30 seconden duren',
  allow_negative_sale: 'Negatieve verkoopprijs toestaan',
  move_current_table_to: 'Tafel ({{table_name}}) verplaatsen NAAR',
  store: 'Locatie',
  print_specific_categories: 'Specifieke categorieën afdrukken',
  customer_display_attached: 'Klantendisplay gekoppeld',
  sale: 'Verkoop',
  reps_and_waste_button_in_cart: 'Laat de knop voor verlies en verspilling zien in de bestelling',
  small_product_tiles: 'Verklein de producttegels',
  large_product_tiles: 'Vergroot de producttegels',
  show_product_price_on_tile: 'Productprijs op tegel',
  printer_roles: 'Printerfuncties',
  paper_size: 'Papiergrootte',
  customer_display: 'Klantendisplay',
  enable_realtime_updates: 'Schakel real-time updates van de server in',
  show_open_orders_first: 'Toon geopende bestellingen als eerste in de bestellijst',
  blank_lines_order_ticket: 'Lege ruimte boven aan het orderticket',
  blank_lines_bottom_order_ticket: 'Lege ruimte beneden aan het orderticket',
  scan_card: 'Scan kaart',
  scan_nfc_or_barcode: 'NFC kaart of code scannen',
  show_product_keypad: 'Cart numpad laten zien',
  extra_payment_methods: 'Extra betaal methodes',
  failed_recovery: 'Betaling herstellen niet gelukt',
  blank_line_between_order_items: 'Lege ruimte tussen orderticket artikelen',
  look_up_customers_using_barcodes: 'Klant toevoegen met barcode scanner',
  error_loading_configuration: 'Configuratie laden mislukt',
  error_verifying_payment_terminal: 'Betaalterminal verificatie mislukt',
  error_identifying_payment_terminal: 'Identificeren van terminal mislukt',
  error_saving_configuration: 'Configuratie opslaan mislukt',
  conflicting_server_modes: 'Pin terminal en server conflict - TEST vs PRODUCTIE',
  nothing_to_print: 'Geen artikelen om af te drukken',
  large_item_name: 'Vergroot de artikelnaam',
  print_ean_sku: 'EAN en SKU afdrukken',
  select_category_on_startup: 'Categorie selecteren bij herstarten',
  split_tickets_item: 'Splits het printen van bestellingen op artikel',
  split_tickets_amount: 'Splits het printen van bestellingen op hoeveelheid',
  split_print: 'Splits het printen',
  refreshing_customers: 'Klanten verversen…',
  downloading_customers: 'Klanten downloaden...',
  error_retrieve_customers:
    'Fout tijdens het opvragen van uw klanten.\nCheck uw internet verbinding en probeer het nogmaals',
  server_error_please_retry: 'Server foutmelding. Probeer opnieuw',
  vibrate_on_click: 'Trillen na klikken op item',
  keep_screen_on: 'Geen slaapmodus',
  refund_same_method: 'Alleen mogelijk om met originele betaalmethode te restitueren',
  cannot_refund_more_than_paid:
    'Het is alleen mogelijk om het een maximale bedrag van {{max_amount}} te restitueren via {{payment_method}}',
  tablet_date_incorrect: 'Uw computerklok is verkeerd - mogelijk beveiligingsrisico',
  extra_non_revenue_info: 'Vraag om extra gegevens voor giftcards',
  enter_extra_info: 'Voer extra artikelinformatie in',
  giftcard_or_reference_no: 'Cadeaukaart of referentienummer',
  inserted_code_not_valid: 'Ingevoerde login is niet juist',
  validate_user: 'Account verificatie ({{user_address}})',
  enter_validation_code: 'Voer verificatiecode in die we u via sms of email hebben toegestuurd',
  tax_rate: 'BTW %',
  pretax: 'Netto',
  deposit: 'Statiegeld',
  can_not_refund_with_different_provider:
    'Restituties niet beschikbaar voor betaaldienst {{txn_payment_method}} via {{selected_payment_method}}',
  external_scanner_connected: 'USB barcodescanner',
  cancelling: 'Cancelling',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  rabosmartpinttp: 'Rabo SmartPIN TapToPay',
  exchange: 'Ruilen',
  ask_exchange: 'Artikelruilen toestaan',
  exchange_error: 'Fout met ruilen',
  exchange_items: 'Artikelen ruilen',
  exchangeable: 'Ruilbaar',
  to_be_exchanged: 'Om te ruilen',
  exchange_all: 'Alles',
  negative_sale_amount: 'Bedrag te betalen minder dan 0',
  download_rabosmartpin_app: 'Download de Rabo SmartPin App',
  transaction_disabled: 'Third party betalingen zijn niet toegestaan op het contract',
  transaction_in_progress: 'Er wordt al een andere betaling uitgevoerd',
  refund_offline: 'Retourtransactie niet mogelijk in beperkte modus',
  refund_not_allowed: 'Retourtransactie niet toegestaan voor ingelogde gebruiker',
  cash_forbidden: 'Contante transacties zijn niet toegestaan op het contract',
  notify_new_online_order: 'Geef melding toen online bestellingen worden ontvangen',
  print_new_online_order: 'Online bestellingen automatisch afdrukken',
  delivery_information: 'Bestelinfo',
  voucher_already_processed: 'Voucher al verwerkt',
  no_applicable_items_in_cart: 'Geen artikelen van toepassing in bestelling',
  discount_applied: 'Korting toegevoegd',
  invalid_voucher: 'Niet geldig',
  allow_voucher_scan: 'Korting toevoegen met barcode scanner',
  enable_drop_shipping: 'Drop-shipping inschakelen',
  ask_no_covers_or_takeaway: 'Vraag om tafelschikking',
  table_configuration: 'Tafelschikking',
  num_covers: 'Aantal gasten',
  takeaway: 'Meenemen',
  guests: 'Gasten',
  show_floorplan_after_sale: 'Toon tafelplan na betaling',
  lock_after_x_minutes: 'Vergrendel na x minuten zonder gebruik',
  show_deposit_items: 'Toon statiegeld',
  pickup: 'Ophalen',
  delivery: 'Bezorgen',
  transaction_fee: 'Transactiekosten',
  print_description: 'Omschrijving artikel afdrukken',
  only_cash_for_negative_amounts:
    'Alleen contant of handmatig pinpas betaling toegestaan toen bedrag te betalen minder dan 0 is',
  phone_number_example: 'Telefoonnummer - bijv. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp niet beschikbaar op deze apparaat',
  receipt_not_yet_synchronised: 'Transactie-synchronisatie in behandeling - probeer later opnieuw',
  notify_online_orders_with_sound: 'Speel geluid af toen online bestellingen worden ontvangen',
  restrict_online_orders_by_floor:
    'Accepteer alleen online bestellingen voor bepaalde verdiepingen',
  remove_product: 'Verwijder artikel',
  admin_access_needed: 'Deze functie is alleen beschikbaar voor Admin gebruikers',
  customer_or_employee_name: 'Naam klant of werknemer',
  show_cart_on_left: 'Toon bestelling aan de linkerkant',
  groupOrderPrintByCategory: 'Groepeer het printen van bestellingen op categorie',
  handpoint_shared_secret: 'SharedSecret',
  fiscal_offline: 'Om fiscale redenen moet POS en FDM online zijn om transacties te verwerken',
  unsynced_transaction_cant_reissue:
    'Transactie nog niet gesynchroniseerd met server - heruitgave niet mogelijk',
  fiscal_info: 'Controlegegevens',
  create_bundle: 'Maak een bundel',
  select_items_to_bundle: 'Selecteer items om te bundelen',
  bundle_price: 'Bundelprijs',
  create_bundle_items: 'Maak een bundel producten op de bestelling',
  bundle: 'Bundel',
  giftcard_or_reference_no_mandatory: 'Cadeaukaart- of referentie-nummer verplicht',
  show_camera: 'Gebruik de camera van het apparaat (indien beschikbaar) als scanner',
  switch_camera: 'Andere camera',
  camera: 'Camera',
  print_qr_on_receipts: 'Print QR code op de bon',
  no_floorplans_available: 'Geen tafelplan beschikbaar',
  receive_online_orders_for_multiple_stores: 'Ontvang bestellingen voor meerdere locaties',
  inventory: 'Voorraad',
  description: 'Omschrijving',
  brand: 'Merk',
  allergens: 'Allergenen',
  hide_total_num_items_on_order: 'Verberg aantal artikelen op de bon',
  loss: 'Kwijt',
  stolen: 'Gestolen',
  own_use: 'Eigen gebruik',
  giveaway: 'Weggeven',
  breakage: 'Breuk',
  student_check: 'student-ID controle',
  overview: 'Overzicht',
  average: 'Gemiddelde',
  busiest_hours: 'Drukste uren',
  rotation_time_in_secs: 'Beeldrotatietijd in seconden',
  show: 'Laten zien',
  uploaded: 'geüpload',
  videos: 'Video',
  photos: "Foto's",
  display_video_or_picture: "Geef video, foto's of pdf-bestanden weer",
  store_logo: 'Logo opslaan',
  add_selected_addon: 'Add-ons toevoegen / verwijderen',
  add_selected_to_stores: 'Toevoegen / verwijderen aan locaties',
  add_selected_categories: 'Geselecteerde categorieën toevoegen / verwijderen',
  select_categories: 'Selecteer categorieën',
  add_selected_addon_group: 'Addon-groep toevoegen / verwijderen',
  company: 'Bedrijf',
  assortment: 'Assortiment',
  sales_history: 'Verkoop Geschiedenis',
  employees: 'Werknemers',
  extensions: 'Uitbreidingen',
  desktop: 'Desktop',
  helpdesk: 'Helpdesk',
  specific: 'Specifiek',
  no_data_for_this_period: 'Geen gegevens gevonden.',
  gross_revenue: 'Bruto inkomsten',
  avg_per_transaction: 'Gem. per transactie',
  from: 'van',
  select_period: 'Selecteer periode',
  date_start: 'Startdatum',
  date_end: 'Einddatum',
  select_store: 'Locatie selecteren',
  select_stores: 'Locaties selecteren',
  all_stores: 'Alle locaties',
  all_employees: 'Alle werknemers',
  all_devices: 'Alle apparaten',
  no_transactions_in_this_period: 'Geen transacties in deze periode.',
  transactions_in_this_period: 'Transacties in deze periode.',
  report_graph: 'Rapportgrafiek',
  no_reports_found_for_this_period: 'Geen rapporten gevonden voor deze periode',
  top_sales: 'Topverkopen',
  top_sellers: 'Top verkopers',
  bottom_sellers: 'Bottom-verkopers',
  no_top_sales_for_this_period: 'Geen topverkopen voor deze periode',
  best_selling_products_for_this_period: 'Best verkochte producten voor de periode',
  save_email: 'Bewaar email',
  new_password: 'Nieuw paswoord',
  verify_password: 'Bevestig wachtwoord',
  save_password: 'Wachtwoord opslaan',
  contact: 'Contact',
  name_first: 'Voornaam',
  name_last: 'Achternaam',
  zip_code: 'Postcode',
  region: 'Regio',
  billing: 'Factuur',
  optional: 'Toevoeging',
  business_name: 'Bedrijfsnaam',
  vat: 'BTW',
  btw: 'BTW',
  save_profile_info: 'Sla profielinformatie op',
  integrations: 'Integraties',
  connect_to_your_existing: 'Maak verbinding met je bestaande',
  invoices: 'Facturen',
  code: 'Code',
  isPaid: 'is Betaald',
  invoice_paid: 'Factuur Betaald',
  actions: 'Acties',
  stores: 'Locaties',
  updated: 'Bijgewerkt',
  updated_at: 'Bijgewerkt om',
  create: 'Maak',
  info: 'info',
  currencies: 'Valuta',
  save_edit_details: 'Bewaar en bewerk details',
  assigned_employees_of_this_stores: 'Toegewezen medewerkers van deze locatie:',
  not_assigned_employees: 'Niet toegewezen werknemers:',
  add_payment: 'Betaling toevoegen',
  payment_name: 'Betalingsnaam',
  printer_name: 'Printer naam',
  edit_storess_main_details: 'Bewerk de belangrijkste details van de locaties.',
  edition: 'Editie',
  stores_address_details: 'Adresgegevens van de locatie.',
  stores_contact_details: 'De contactgegevens van de locatie.',
  assign_employees_for_your_stores: 'Ken medewerkers toe voor uw locatie.',
  create_custom_payment_methods_for_your_store: 'Maak aangepaste betaalmethoden voor uw locatie.',
  devices: 'Apparaten',
  device: 'Apparaat',
  hardware_info: 'Hardware-info',
  uuid: 'UUID',
  manufacturer: 'Fabrikant',
  os_name: 'OS Naam',
  os_version: 'OS Versie',
  pos_version: 'POS Versie',
  receipt_layout: 'Bon lay-out',
  table_layout: 'Horeca vloerplan',
  default_receipt: 'Standaar design',
  email_receipt: 'E-mail bon',
  change_image: 'Verander afbeelding',
  Tuesday: 'Dinsdag',
  june: 'Juni',
  your_store_address: 'Uw locatieadres',
  edit: 'Bewerk',
  your_text_here: 'Jouw tekst hier',
  cheap: 'Goedkoop',
  expensive: 'Duur',
  client_receipt: 'Ontvangst van klant',
  transaction_agreement: 'Transactie overeenkomst',
  your_footer_message_here: 'Je footer-bericht hier',
  image: 'Afbeelding',
  price: 'Prijs',
  edit_products_main_details: 'Bewerk de belangrijkste details van het product.',
  details: 'Details',
  visible: 'Zichtbaar',
  in_pos: 'in POS',
  cost: 'Kosten',
  cost_price: 'Kostprijs',
  delivery_price: 'Bezorgkosten',
  upload_progress: 'Upload de voortgang',
  change_products_image: 'Verander de afbeelding van het product',
  variants: 'Varianten',
  variant: 'Variant',
  units: 'Units',
  alert: 'Alarm',
  additional: 'Extra',
  ledger: 'Grootboek',
  subledger: 'Subgrootboek',
  addon: 'Add-on',
  addon_groups: 'Groepen add-on',
  addon_group: 'Groep add-on',
  add_delete_and_edit_products_variants:
    'Varianten van producten toevoegen, verwijderen en bewerken.',
  assing_product_to_store:
    'Product toewijzen aan een specifieke locatie (als er geen locatie is geselecteerd, verschijnt het product in elke locatie).',
  products_addtional_fields: 'Product extra velden.',
  manage_addons_group: 'Addons-groep',
  sold_by_weight: 'Verkocht o.b.v. gewicht',
  dynamic_price: 'Dynamische prijs',
  dynamic: 'Dynamische prijs',
  min_age: 'Minimum leeftijd',
  packaging: 'Verpakking',
  contents: 'inhoud',
  form: 'vorm',
  products_packaging_and_suppliers_details: 'Productverpakkingen en leveranciersdetails.',
  stock: 'Voorraad',
  management: 'Beheer',
  count: 'tellen',
  filter_name: 'Typ om de naamkolom te filteren...',
  find_products_category: 'Zoek producten op categorie',
  find_products_addons: 'Zoek producten op add-ons',
  in_stock: 'Op voorraad',
  order_below: 'Bestel Hieronder',
  order_quantity: 'Bestel Hoeveelheid',
  advance: 'Gevorderd',
  view_history: 'Bekijk geschiedenis',
  click_here_to_select_source: 'Klik hier om de bron te selecteren',
  record_missing_item: 'Record ontbrekend item',
  lost: 'Verlies',
  current_stock: 'Huidige voorraad',
  current_report: 'Huidig rapport',
  view_stock_history: 'Bekijk de voorraadgeschiedenis',
  color: 'Kleur',
  duplicate_item: 'Duplicaat item',
  want_duplicate_item: 'Wil je dit item dupliceren?',
  delete_item: 'Verwijder item',
  want_delete_item: 'Wil je dit item verwijderen?',
  categories_visibility_changed: 'Categorieën zichtbaarheid gewijzigd',
  change_visibility: 'Wijzig de zichtbaarheid',
  category: 'Categorie',
  parent: 'Ouder',
  select: 'Selecteer',
  rate: 'Tarief',
  delete_selected_items: 'Verwijder geselecteerde items',
  aplly_diff_rates: 'Pas verschillende belastingtarieven toe voor verschillende landen',
  countries_added: 'Landen toegevoegd',
  groups: 'Groepen',
  group: 'Groep',
  standard: 'Standaard',
  default_text: 'Plaats hier tekst',
  business_rules: 'Bedrijfsregels',
  business_rule: 'Bedrijfsregel',
  title: 'Titel',
  is_active: 'isActief',
  startdate: 'StartDatum',
  enddate: 'Einddatum',
  rules: 'Reglemnt',
  rule: 'Regel',
  edit_business_rules_main_details: 'Bewerk de hoofddetails van bedrijfsregels.',
  active: 'Actief',
  time_start: 'Starttijd',
  time_end: 'Eindtijd',
  select_specific_days: 'Selecteer specifieke dag(en)',
  conditions: 'Voorwaarden',
  condition: 'Voorwaarde',
  change_business_rules_conditions: 'Wijzig de voorwaarden van bedrijfsregels.',
  change_business_rules_outcome: 'Verander de uitkomst van de bedrijfsregel.',
  minimum_amount: 'Minimale hoeveelheid',
  minimum: 'Minimum',
  maximum: 'Maximum',
  maximum_amount: 'Maximaal hoeveelheid',
  ignore_for: 'Negeren voor',
  outcome: 'Resultaat',
  voucher_outcome: 'voucher resultaat',
  new_price_rule: 'Nieuwe prijs voor bedrijfsregel',
  min_pack_value: 'Min packwaarde',
  max_pack_value: 'Max packwaarde',
  enable: 'Regels inschakelen',
  disable: 'Regels uitschakelen',
  all: 'Alle',
  update_time: 'Update start/eindtijd',
  update_date: 'Update start/einddatum',
  catalog_order: 'Catalogus volgorde',
  drag_and_drop_items_to_reorder: 'Versleep items om opnieuw te ordenen',
  see_reports: 'U kunt uw rapporten in het dashboard bekijken door te selecteren',
  generate_report: 'Genereer Rapport',
  or: 'of',
  send_report: 'U kunt onbewerkte gegevens naar uw e-mail verzenden',
  send_report_button: 'Verzend Rapport',
  send_to: 'Verzenden naar',
  recipient: 'e-mailadres van de ontvanger',
  available_reports: 'Rapporten beschikbaar voor deze periode',
  select_currency_text: 'Selecteer een valuta of een specifieke locatie.',
  tables: 'Tafels',
  revenue: 'Inkomsten',
  see_revenue: 'Zie uw bruto-inkomsten, kosten, belastingen en winst',
  net_revenue: 'Netto inkomsten',
  profit: 'Winst',
  see_transaction: 'Bekijk uw transactiemethode, het aantal en het totale bedrag',
  see_payments: 'Bekijk uw betalingswijze, hoeveelheid en totaalbedrag',
  see_refunds: 'Bekijk uw terugbetalingsmethode, hoeveelheid en totaalbedrag',
  see_taxes: 'Bekijk uw belastingen, het aantal en het totale bedrag',
  brands: 'Merken',
  channels: 'Kanalen',
  channel: 'Kanaal',
  see_brands: 'Bekijk uw producten op merk, aantal en totaalbedrag',
  see_channels: 'Bekijk uw producten op kanaal, aantal en totaalbedrag',
  see_categories: 'Bekijk uw categorieën, het nummer en het totale bedrag',
  click_details: 'Klik hier voor details',
  report_select_other_period: 'Selecteer een andere periode om het beschikbare rapport te bekijken',
  created: 'Gemaakt',
  discounts: 'Korting aanmaken',
  add_discount: 'Korting aanmaken',
  discount_percent: 'Kortingspercentage',
  register_opertations: 'Operationele activiteiten',
  operations: 'Operaties',
  first: 'Eerste',
  last: 'Laatste',
  phone: 'Telefoon',
  permission_to_contact: 'Toestemming om contact op te nemen',
  identifiers_type: 'Type klant-ID',
  identifiers_value: 'Identifiers waarde',
  shipping: 'Verzenden',
  diff_billing: 'indien afwijkend van factuuradres',
  rights: 'Rechten',
  employee: 'Werknemer',
  token: 'Token',
  uninstall_ext: 'Extensie verwijderen',
  active_ext: 'Deze extensie is actief',
  subscribe: 'Abonneren',
  remove_ext_msg: 'Weet je zeker dat je deze extensie wilt verwijderen?',
  no_thanks: 'Nee bedankt',
  subs_to_ext: 'Abonneren op Extensies',
  period: 'Periode',
  payment_details: 'Betalingsdetails',
  card_name: 'Naam op kaart:',
  paymnet_method: 'Betalingsmiddel:',
  code_country: 'Landcode:',
  diff_way_pay_msg: 'Of kies een andere manier om te betalen',
  phone_support: 'Telefonische Ondersteuning',
  support_msg: 'Neem contact op met de klantenondersteuning voor assistentie:',
  email_support: 'E-mail Ondersteuning',
  support_email: 'Ondersteuning e-mail:',
  info_email: 'Info e-mail:',
  sales_email: 'Verkoop e-mail:',
  page: 'pagina',
  dark_theme: 'Donker Thema',
  report_spec_time: 'Genereer rapport met een specifieke tijd',
  vat_btw_no: 'VAT/BTW No.',
  store_unable_delete:
    'Kan de locatie niet verwijderen omdat een apparaat is gekoppeld. Verwijder het apparaat voordat u de locatie verwijdert.',
  important: 'Belangrijk:',
  store_device_delete_msg:
    'Zorg ervoor dat u bent uitgelogd van dit apparaat voordat u het verwijdert.',
  email_test_receipt: 'E-mail een testbon',
  merchant: 'Merchant',
  per_item: 'per artikel',
  img_width_msg: 'Maximale toegestane bestandsbreedte is 250px.',
  err_img_msg: 'Fout: de breedte van de afbeelding mag niet groter zijn dan 250px',
  rules_actions: 'Regels Acties',
  enable_all: 'Schakel alle bedrijfsregels in',
  want_enable_all: 'Wilt u alle bedrijfsregels inschakelen?',
  disable_all: 'Schakel alle bedrijfsregels uit',
  want_disable_all: 'Wilt u alle bedrijfsregels uitschakelen?',
  product_cat_error: 'Gelieve een categorie te kiezen',
  field_not_empty: 'Veld mag niet leeg zijn.',
  drop_files: 'Zet je bestand hier neer om de afbeelding te uploaden.',
  save_variant_before_stock: 'Sla de nieuwe variant op vóór bewerking.',
  no_employees: 'U hebt geen werknemers. AUB werknemers creëren of toevoegen',
  here: 'hier',
  no_stock: 'Er zijn geen voorraadproducten in deze locatie',
  product_exists: 'Dit product bestaat alleen in',
  add_units_stock: 'Apparaten aan voorraad toevoegen',
  remove_units_stock: 'Uit voorraad nemen van eenheden',
  saving: 'Is opslaan',
  transfer_units: 'Voeg het aantal eenheden toe om over te zetten',
  remove_units_store: 'Voeg het aantal eenheden toe om uit deze locatie te verwijderen',
  notification: 'kennisgeving',
  set_new_start_end_time: 'Stel een nieuwe start/eindtijd in',
  set_new_start_end_date: 'Stel een nieuwe start/einddatum in',
  by_email: 'per email',
  charts_graphs: 'Charts/Graphs',
  compare_reports: 'Vergelijk rapporten',
  new_report: 'Nieuw Rapport',
  basic: 'Basis',
  no_acount_to_send_report: 'U kunt dit rapport niet verzenden omdat uw account dit is',
  upgrade_account_msg: 'U moet uw account bijwerken!',
  gen_report_msg: 'U kunt uw rapport genereren en de resultaten in het dashboard bekijken',
  disabled_report: 'Rapporten uitgeschakeld terwijl betaling(en) uitstaan',
  please_check: 'Controleer alstublieft uw',
  email_wrong_format: 'Het e-mailformaat is niet correct.',
  bar_chart: 'Bar Chart',
  method: 'Methode',
  total_taxed_amount: 'Totaal belast bedrag',
  total_price_product: 'Totale prijs van producten',
  enter_value: 'U moet een waarde invoeren',
  not_valid_mail: 'Geen geldige e-mail',
  enter_email: 'Voer uw e-mailadres in',
  unit_price: 'Eenheidsprijs',
  extras: 'Extras',
  monthly_billed: 'maandelijks gefactureerd',
  removing_ext: 'Deze extensie wordt de volgende betalingscyclus verwijderd',
  local_ip: 'Lokaal IP',
  printer_ip_ad: 'IP-adres van de printer',
  printer_categories: 'Printercategorieën',
  printers: 'Printers',
  payment_processor: 'Betalingsverwerker',
  question: 'Heb je een vraag ?',
  check_here: 'De meest gestelde vragen worden in ons',
  knowledge_center: 'Kenniscentrum',
  support_articles_msg:
    'Hier vind je artikelen die uitleg geven over de aanmaak van een product, categorie, prijzen toevoegen, BTW percentages instellen en meer...',
  support_ques:
    'Wil je rechtstreeks met onze Servicedesk spreken, klik dan op het contact icoontje rechtsonder in het Dashboard.',
  in_touch: 'klik dan op het contact icoontje rechtsonder in het Dashboard.',
  select_floorplan: 'Selecteer plattegrond',
  floors: 'Vloeren',
  add_floorplan: 'Voeg plattegrond toe',
  delete_floorplan: 'vloerplan verwijderen',
  floorplan: 'Plattegrond',
  add_tables: 'Voeg tabellen toe',
  sign_up_msg:
    'Duizenden handelaars hebben zich bij Countr aangesloten. Vandaag is het jouw beurt. Gratis inschrijven.',
  user_required: 'Gebruikersnaam is vereist.',
  name_required: 'Naam is vereist.',
  company_name_required: 'Bedrijfsnaam is verplicht.',
  phone_required: 'Telefoonnummer is verplicht.',
  pass_required: 'Een wachtwoord is verplicht.',
  already_account: 'Heb je al een account?',
  accept_sign_up: 'Door in te schrijven ga ik akkoord met de',
  terms_conditions: 'Algemene Voorwaarden',
  policy: 'Privacybeleid',
  licence_agree: 'Licentieovereenkomst',
  your_name: 'Uw naam',
  company_web: 'Bedrijfswebsite (niet verplicht)',
  forgot_pass: 'Wachtwoord vergeten?',
  login: 'Inloggen',
  no_account_link: 'Nu het registreren?',
  email_required: 'E-mail is verplicht.',
  send_link: 'Stuur link',
  download_file: 'Download Bestand',
  account_number: 'Rekeningnummer',
  me_rules: 'Reglemnt',
  by_volume: 'Best verkopende producten (per volume) dit periode',
  number_sold: 'Nummer verkocht',
  by_volume_bottom: 'Bottom-selling producten (per volume) dit periode',
  create_compare_report: 'Maak een nieuw rapport om te vergelijken',
  last_per: 'Laatste',
  no_per_data: 'heeft geen beschikbare rapporten',
  create_new_rep: 'Maak een nieuw rapport',
  reset_pass_text: 'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen',
  enter_bus_info: 'Bitte geben Sie Ihre Geschäftsinformationen ein',
  organization: 'Name der Organisation',
  mandatory_fields: 'Alle Felder sind Pflichtfelder !!!',
  invalid_eu: 'Ungültige Umsatzsteuer-Identifikationsnummer für EU-Land',
  trial_expires: 'Uw proefperiode loopt af in',
  select_package: 'Selecteer pakket',
  excluse_vat: 'exclusief BTW',
  instead: 'in plaats van',
  save_payment: 'Bewaar deze betaalmethode voor toekomstige transacties',
  procced_paymet: 'Ga door naar betaling',
  selected_package: 'Geselecteerd pakket',
  selected_ext: 'Geselecteerde extensies',
  old_dashboard: 'Switch dashboard',
  subscription_msg:
    'Gefeliciteerd, je staat op het punt om bij je locatie te gaan wonen! Uw transacties worden voor altijd bewaard en alle testbranding verdwijnt. Voordat u live gaat, moet u de extensies kiezen die u wilt blijven gebruiken.',
  transfer_from: 'Transfer eenheden van',
  customer_purchase: 'Klant aankopen',
  total_spend: 'Totaal uitgegeven',
  last_customers_transactions: 'Laatste transacties',
  email_recipient: 'e-mailadres van de ontvanger',
  show_revenue: 'Omzet weergeven',
  show_sales: 'Verkoop weergeven',
  visits: 'Totaal aantal bezoeken',
  select_action: 'Selecteer actie',
  show_receipt: 'Ontvangstbewijs tonen',
  set_values_for: 'Stel waarden in voor',
  update: 'Bijwerken',
  select_addons_group: 'Selecteer addons-groep',
  select_addon: 'Selecteer add-on',
  all_addon_groups_are_in_use: 'Lijst is leeg, alle add-on groepen zijn in gebruik',
  store_hours: 'Openingstijden',
  opening_hours: 'Openingstijden',
  no_opening_hours: 'Geen openingstijden',
  set_opening_hours: 'Stel openingstijden in',
  delivery_hours: 'Delivery Hours',
  no_delivery_hours: 'Geen bezorguren',
  set_delivery_hours: 'Stel bezorguren in',
  ordering_hours: 'Uren bestellen',
  no_ordering_hours: 'Geen besteltijden',
  set_ordering_hours: 'Stel besteltijden in',
  takeaway_hours: 'Afhaaluren',
  no_takeaway_hours: 'Geen afhaaluren',
  set_takeaway_hours: 'Stel afhaaluren in',
  delivery_zones: 'Afleverzones',
  no_delivery_zones: 'Geen leveringszones',
  set_delivery_zones: 'Bezorgzones instellen',
  Monday: 'Maandag',
  Thursday: 'Donderdag',
  Friday: 'Vrijdag',
  Saturday: 'Zaterdag',
  Sunday: 'Zondag',
  set_opening_store: 'Stel openingstijden voor de locatie in',
  set_delivery_store: 'Stel bezorguren in voor de locatie',
  set_ordering_store: 'Stel besteltijden in voor de locatie',
  set_takeaway_store: 'Stel afhaaluren in voor de locatie',
  set_zones_store: 'Bezorgzones instellen voor de locatie',
  no_data_added: 'Geen gegevens toegevoegd',
  add_hours: 'Uren toevoegen',
  add_zones: 'Leveringszones toevoegen',
  order_sub: 'Sorteer subcategorieën',
  new_item: 'nieuw',
  tips: 'tip',
  see_busiest_hours: 'Bekijk de drukste uren op basis van de geselecteerde periode',
  see_employee_transactions: 'Bekijk transacties per medewerker voor de geselecteerde periode',
  see_employee_tips: 'Veja a dica por funcionário para o período selecionado',
  see_employee_refunds: 'Zie terugbetalingen per werknemer voor de geselecteerde periode',
  see_customer_transactions: 'Bekijk transacties per klanttype voor de geselecteerde periode',
  see_customer_refunds: 'Bekijk terugbetalingen per klanttype voor de geselecteerde periode',
  new_customer: 'Nieuwe klant',
  returning_customer: 'Terugkomende klant',
  no_customer: 'Geen klantgegevens',
  reps_waste: 'Verlies en verspilling',
  see_reps_waste: 'Zie verlies en verspilling per reden',
  see_employee_reps_waste: 'Zie Verlies en verspilling per medewerker',
  employee_report_graph: 'Bekijk de verkopen van medewerkers per keer',
  not_sold: 'Niet verkocht',
  non_selling_products_this_period:
    'Niet-verkopende producten in deze periode (producten met 0 verkopen)',
  compare_stores: 'Vergelijk locaties',
  average_amount_spent: 'Gemiddeld besteed bedrag',
  average_amount_refunded: 'Gemiddeld terugbetaald bedrag',
  bundle_parts: 'Bundel onderdelen',
  create_giftcard: 'Maak een cadeaukaart',
  giftcards: 'Cadeaubonnen',
  select_graphs_type: 'Selecteer het grafiektype',
  see_discount_percentage: 'Zie transacties met kortingspercentage',
  see_discount_by_product_percentage:
    'Zie korting gegeven aan producten (numerieke kortingen worden berekend als percentage)',
  discount_percentage: 'Korting (percentage)',
  discount_numeric: 'Korting (numeriek)',
  see_discount_numeric: 'Zie transacties met numerieke korting',
  number_of_discounts:
    'en het aantal keren dat deze korting optrad op product- of bestellingniveau',
  delivery_cost: 'Bezorgkosten',
  total_cash_after_tips_and_change: 'Totaal geld na fooien en wisselgeld',
  assigned_stores: 'Toegewezen locaties',
  chat_iframe: 'Chat',
  mobile_phone: 'Mobiele telefoon',
  notifications: 'Kennisgevingen',
  annualy_billed: 'jaarlijks gefactureerd',
  download_stock: 'Download producten met voorraad',
  download_all_stock: 'Download alle producten',
  no_stock_products: 'Producten zonder voorraad instellen',
  go_products_with_stock: 'Ga naar producten met voorraad',
  save_all_changes: 'Sla alle veranderingen op',
  need_track_stock: 'U moet de voorraad volgen om te bewerken.',
  track_stock: 'Track voorraad',
  set_min_man: 'Stel min en max in voor de specifieke addon-groepen',
  duplicate: 'Duplicaat',
  clear_unsaved: 'Wis alle wijzigingen',
  select_allergens: 'Selecteer Allergenen',
  translate_categories: 'Vertaal categorieën',
  translate_groups: 'Groepen vertalen',
  add_cat_translation: 'Vertalingen toevoegen aan categorie',
  add_group_translation: 'Vertalingen toevoegen aan groep',
  assigned_groups: 'Toegewezen groepen',
  extension: 'Uitbreiding',
  vouchers: 'Vouchers',
  show_open_carts_from_store: 'Toon openstaande orders vanuit de locatie',
  ean: 'Barcode (EAN)',
  sku: 'SKU',
  want_delete_all_items: 'Wilt u alle geselecteerde items verwijderen?',
  want_to_change_visibility: 'Wilt u de zichtbaarheid van alle geselecteerde items wijzigen?',
  import_export: 'Importeren / exporteren',
  methods: 'Betalingswijze',
  stored_payment_info: 'Uw opgeslagen betalingsgegevens bekijken',
  stored_payment: 'Betaalmethode',
  report_spec_zone: 'Selecteer een andere tijdzone',
  no_stock_stores:
    'Je hebt geen locatie. Maak een locatie en wijs producten toe aan de inventaris.',
  create_custom_payment: 'Maak aangepaste betaalmethoden voor uw locatie.',
  min_chars: 'minstens 3 karakters',
  enable_deposit: 'Activeer statiegeld',
  enable_daily_report: 'Dagelijks rapport inschakelen',
  reorder_email: 'Stock Reorder Email',
  enable_non_revenue: 'Schakel niet-inkomsten in',
  total_visits: 'Totaal aantal bezoeken',
  how_it_works: 'Hoe het werkt',
  info_text_catalog_order_one:
    '•  U kunt uw producten slepen en neerzetten door de cursor boven een item te plaatsen en de linkermuisknop ingedrukt te houden.',
  info_text_catalog_order_two: '•  Om een ',
  verify_dialog: 'Er is een verificatiecode naar uw e-mailadres verzonden.',
  verify_proceed_dialog:
    'Selecteer een branchetype van uw bedrijf en ga vervolgens verder met het verifiëren van uw account.',
  export_customer: 'Klanteninformatie exporteren',
  disabled_floor: 'Verdieping uitgeschakeld',
  filter_by_category: 'Filteren op Categorie',
  current_timezone: 'Lokaal tijdzone:',
  important_note: 'Belangrijke notitie',
  transaction_note:
    'Er worden geen kosten in rekening gebracht, maar we hebben uw betalingsgegevens nodig, zodat we uw verzoek om live te gaan kunnen valideren.',
  translate_name: 'Stel vertaling in voor de geselecteerde talen',
  translate_tool: 'Geef vertaling aan naam',
  payment_method_note:
    'U kunt alleen de betaalmethode verwijderen en niet toevoegen of bewerken. U kunt een nieuwe betaalmethode toevoegen terwijl u',
  add_new_payment: 'betaalt of een nieuwe',
  no_payment_method: 'Er is geen opgeslagen betaalmethode',
  daily_report: 'Dagelijks rapportuur (24-uursklok)',
  it_seems_store: 'Het lijkt erop dat',
  no_stock_items: 'onvoldoende items op voorraad heeft. Weet u zeker dat u door wilt gaan?',
  export_file_finished: 'Uw exportbestand is voltooid, hier is uw downloadlink:',
  import_file_finished: 'Uw importbestand is succesvol voltooid. Producten totaal:',
  stock_import_file_finished: 'Uw Stock Importer-bestand is succesvol voltooid.',
  error_stock_finished:
    'Er is een fout opgetreden, controleer uw bestand op eventuele verkeerde gegevens',
  import_file_init:
    'We zijn uw bestand aan het verwerken. Je krijgt een melding wanneer het klaar is.',
  export_file_init:
    'We zijn uw bestand aan het verwerken. Je krijgt een melding wanneer het klaar is.',
  create_voucher: 'Maak een voucher aan',
  total_uses: 'Bruikbaar bedrag',
  scope: 'Reikwijdte',
  numeric_discount: 'Numerieke korting',
  start_date: 'Startdatum',
  expiry_date: 'Experimenteer datum',
  products_categories: 'Producten of categorieën',
  search_products: 'Zoek producten',
  selected_products: 'Geselecteerde producten',
  search_categories: 'Zoek categorieën',
  selected_categories: 'Geselecteerde categorieën',
  selected_items: 'Geselecteerde voorwerpen',
  local_prices: 'Lokale prijzen',
  translate: 'Vertalen',
  value: 'Waarde',
  code_type: 'Code type',
  non_revenue: 'Geen inkomsten',
  giftcard_product_alert:
    'Een cadeaukaartproduct moet altijd een product zijn dat geen inkomsten oplevert.',
  multi_countries_tax_rules: 'Belastingtarieven voor meerdere landen',
  countries_rates: 'Landen tarieven',
  edit_store_themes: "Locatiethema's bewerken (alleen beschikbaar voor Countr Kiosk)",
  themes: "Thema's",
  font: 'Font',
  current_theme: 'Huidige thema',
  add_theme: 'Thema toevoegen',
  delete_theme: 'Thema verwijderen',
  body_background: 'Achtergrond kleur',
  body_text: 'Tekst kleur',
  primary_text: 'Primaire tekstkleur',
  secondary_text: 'Secundaire tekstkleur',
  header: 'Achtergrondkleur koptekst',
  header_text: 'Kleur koptekst',
  toolbar: 'Achtergrondkleur werkbalk',
  toolbar_text: 'Tekstkleur werkbalk',
  button_primary_text: 'Tekstkleur primaire knop',
  button_primary_background: 'Achtergrondkleur primaire knop',
  button_secondary_text: 'Tekstkleur secundaire knop',
  button_secondary_background: 'Achtergrondkleur secundaire knop',
  button_neutro_text: 'Tekstkleur van de neutrale knop',
  button_neutro_background: 'Achtergrondkleur van de neutrale knop',
  isActive: 'isActive',
  Wednesday: 'Woensdag',
  answered: 'beantwoord',
  common: { welcome: 'Hallo {{name}}, je heeft {{count}} nieuwe {{messages}}!' },
  username: 'Gebruikersnaam',
  show_price: 'Toon prijs',
  companyid: 'Bedrijfsnaam',
  invalid_merchantid: 'Ongeldige contactpersoon',
  invalid_businessid: 'Ongeldige bedrijfsnaam',
  all_categories: 'Alle categorieen',
  close_search_product: 'Zoekopdracht sluiten en zoekfilter verwijderen',
  firstid: 'Voornaam',
  lastid: 'Achternaam',
  storeid: 'Locatienaam',
  enter_a_printerid: 'Voer een printernaam in',
  enter_orderid: 'Voer de naam van het bestelling in',
  enter_tableid: 'Voer tabelnaam in',
  customerid: 'Klant',
  insert_printerid: 'Voer een (3 tekens of meer) naam in voor uw printer.',
  not_paid: 'Niet betaald',
  menu_itemid: 'Naam menu-item (max. 12 tekens)',
  employeeid: 'Medewerker',
  deviceid: 'Apparaat',
  addid_device: 'Geef uw apparaat een naam',
  deviceid_mandatory: 'Apparaat naam is verplicht',
  no_floorplans: 'Geen tafelplan beschikbaar',
  table_layout_advice: 'Maak een plattegrond in de backoffice om deze functie te gebruiken',
  merge_tables: 'Tafels samenvoegen',
  custom_item_not_valid: 'Aangepast item niet geldig',
  no_connection_sync:
    'Om alle transacties te synchroniseren, moet u ervoor zorgen dat de verbinding opnieuw tot stand is gebracht voordat u de applicatie afsluit.',
  new_device_name: 'Nieuwe apparaatnaam',
  change_device_name: 'Apparaatnaam wijzigen',
  change_store: 'Huidige locatie wijzigen',
  alert_change_store: 'Sluit en herstart de app volledig om deze verandering weer te geven',
  customer_total_spend: 'Totale uitgaven van de klant',
  skip_payment_success_info: 'Geslaagd betaling informatie overslaan',
  cart_always_open: 'bestelling altijd open',
  show_calculator: 'Toon rekenmachine',
  selected: 'Geselecteerd',
  categories_tiles_position: 'Positie categorietegels',
  kiosk_mode: 'Kioskmodus',
  kiosk_mode_disabled: 'Kioskmodus uitgeschakeld',
  change_table: 'Tafelnaam wijzigen',
  change_cart: 'Naam bestelling wijzigen',
  change_cart_tip: 'Het moet minimaal 2 letters hebben',
  a4_paper: 'Gebruik A4 printer',
  auto_print: 'Kassabon automatisch afdrukken na geslaagde betaling',
  select_report: 'Selecteer rapporttype',
  report_not_found: 'Rapport niet gevonden',
  new_cart: 'Nieuwe bestelling',
  new_table: 'Nieuwe tafel',
  delete_cart: 'bestelling verwijderen',
  delete_cart_confirmation: 'Weet je zeker dat je deze bestelling wilt verwijderen?',
  product_tile_size: 'Product tegels vergroten',
  load_more_transactions: 'Meer transacties laden',
  select_variant: 'Selecteer variant',
  remember_password: 'Onthoud wachtwoord',
  go_dashboard: 'Ga naar mijn dashboard',
  enable_cart_item_remove: 'Artikel verwijderen toegestaan',
  remove_item: 'Artikel verwijderen',
  show_current_cart: 'Huidige bestelling weergeven',
  show_carts: 'Lijst met bestellingen tonen',
  show_tables: 'Toon plattegrond',
  open_receipt: 'Toon kassabon',
  kds: 'Keuken scherm',
  archive: 'Archief',
  send_archive: 'Verzenden naar archief',
  awaiting_payment: 'in afwachting van betaling',
  all_items: 'Alle artikelen',
  printed: 'Nieuw',
  pending: 'Nieuw',
  preparing: 'Voorbereiden',
  ready: 'Klaar',
  completed: 'Voltooid',
  send_new: 'Terugzetten als nieuw',
  send_progress: 'Verzenden naar wordt uitgevoerd',
  send_ready: 'Verzenden naar gereed',
  send_completed: 'Verzenden naar voltooid',
  active_orders: 'Actieve bestellingen',
  all_orders: 'Alle bestellingen',
  select_employee: 'Selecteer werknemer',
  select_status: 'Selecteer Status',
  send_all_to_status: 'Stuur alle producten naar de status',
  no_open_orders: 'Geen bestellingen beschikbaar',
  click_refresh: 'Klik hier om te vernieuwen',
  deliveredBy: 'Bezorgd door',
  by: 'Bezorgd door',
  deliveryTime: 'Levertijd',
  pickupTime: 'Ophaaltijd',
  customerPhone: 'Telefoonnummer klant',
  customerName: 'Klantnaam',
  orderNote: 'Bestelnotitie',
  serviceCharge: 'Servicekosten',
  orderType: 'Leveringstype',
  deliveryCost: 'Bezorgkosten',
  discountTotal: 'Korting',
  channelOrderDisplayId: 'Order id',
  orderIsAlreadyPaid: 'Is betaald',
  paymentMethod: 'Betalingsmiddel',
  deliveryFee: 'Delivery Fee',
  deliveryAddress: 'Address',
  deliveryPostcode: 'Postcode',
  deliveryCity: 'City',
  extraAddressInfo: 'Extra Info',
  postalCode: 'Postcode',
  streetNumber: 'Straatnummer',
  postponed: 'Uitgesteld',
  warning_delay: 'Waarschuwingsvertragingsopdrachten (in minuten)',
  load_orders_past: 'Laad bestellingen van de afgelopen dagen',
  hotkeys: 'HotKeys',
  show_categories_separator: 'Toon categorieënscheidingsteken op het item van elke bestelling',
  auto_refresh: 'Lijst met automatische verversingsopdrachten elke X minuten',
  auto_complete_order: 'Automatische voltooiing van bestellingen na X minuten',
  hide_completed_items: 'Verberg voltooide items van bestelling',
  remove_grid_gaps: 'Verwijder hiaten tussen de orders van het raster',
  off: 'Uit',
  languages: {
    restart_msg:
      'Sla uw instellingen op en vernieuw uw browser om de nieuwe vertaling te gebruiken.',
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch',
    pt: 'Português',
    es: 'Español',
    fr: 'Français',
    is: 'Íslenska',
    it: 'Italiano',
    no: 'Norsk'
  },
  hotkeys_obj: {
    error: 'Kan toetsen niet herhalen!',
    select_order: 'Selecteer Orders van 1 tot 9',
    change_status_info: 'Wijzig de status van de geselecteerde bestelling met:',
    change_status: 'Wijzig de bestelstatus'
  },
  last_update: 'Laatste update',
  customer_information: { first_name: 'Voornaam', last_name: 'Achternaam', phone: 'Telefoon' },
  in_progress: 'Bezig',
  deliveryBy: 'Bezorgd door',
  init_all_services: 'Alle services initialiseren...',
  email: 'Email',
  signin: 'Inloggen',
  signin_error: 'Er is iets misgegaan tijdens het inloggen',
  device_name: 'Apparaatnaam',
  close_details: 'Details sluiten',
  no_orders_available: 'Geen bestellingen beschikbaar',
  order_has_been_completed: 'Bestelling {{receipt_id}} is voltooid',
  no_products: 'Geen Producten',
  print_device: 'Afdrukapparaat',
  send_order: 'Bestelling verzenden',
  add_products: 'Voeg producten toe',
  no_categories: 'Geen categorieën gevonden',
  empty_table: 'Lege tafel',
  order_not_sent: 'Bestelling niet naar de keuken gestuurd',
  product_list_view: 'Productlijstweergave',
  no_description:
    'Dit product heeft geen beschrijving. U kunt er een toevoegen door op de knop "Wijzigen" te drukken of uw dashboard bezoeken.',
  change_description: 'Voer de beschrijving van het product in.',
  main_cart_is_empty: 'Hoofdbestelling is leeg',
  apply_to_all_carts: 'Toepassen op alle bestellings',
  per_value_payment: '(Per waarde betaling)',
  select_a_seat: 'Selecteer een stoel',
  sub_total: 'Subtotaal',
  desktop_settings: 'Desktop instellingen',
  carts: 'Bestellingen',
  signing_in: 'Inloggen...',
  total_taxes: 'Totale belastingen',
  provide_refund_item: 'U moet een item selecteren dat moet worden terugbetaald',
  manual_cheque_refund: 'Handmatige cheque restitutie',
  downloading_employees: 'Werknemers downloaden...',
  show_product_image_list: 'Toon producten afbeelding in Productlijst',
  refresh_delta: 'Producten/Categorieën vernieuwen',
  admin_pin_incorrect: 'Admin Pin niet correct',
  customer_not_valid_vat: 'Btw klant niet geldig of leeg',
  allow_partial_payment: 'Gedeeltelijke betaling toegestaan',
  allow_qrcode_scanner: 'Sta QR code scanner toe',
  qrcode_not_found: 'QR-code niet gevonden',
  empty_pin: 'Pincode moet minimaal 1 teken bevatten',
  employee_select_error: 'Error werknemer selectie',
  employee_select_success: 'Werknemer {{employee}} geselecteerd',
  employee_lock: 'Werknemer geblokkeerd',
  no_employee_store: 'Er is geen medewerker toegewezen aan deze locatie',
  cash_in_out_failed: 'Kan de in / uit-bewerking van contant geld niet opslaan',
  cash_in_out_success: 'Cash in/out operation success',
  categories_alphabetic_order: 'Categorieen op alfabetische volgorde',
  products_alphabetic_order: 'Producten op alfabetische volgorde',
  weight_not_valid: 'Gevuld gewicht niet geldig',
  kiosk_mode_requirement: 'Kiosk Mode is vereist',
  canceled: 'Geannuleerd',
  partial_payment_value: 'Waarde van gedeeltelijke betaling',
  partial_payment_item: 'Per artikel',
  continue_payment: 'Ga door met betaling',
  unit: 'Eenheid',
  cheque: 'Cheque',
  need_store_register_device:
    'Je moet eerst een locatie aanmaken om te kunnen inloggen. Ga naar jouw online dashboard om dit te doen. ',
  search_transaction_hint: 'Ten minste 3 tekens en druk op ENTER',
  select_pay_method: 'Selecteer betaalmethode',
  delete_all_empty_cart: 'Bestelling leegmaken',
  delete_all_empty_table: 'Tafel leegmaken',
  delete_all_empty_cart_confirmation: 'Bevestig bestelling leegmaken',
  all_empty_carts_deleted: 'Alle bestellings gedelete',
  reps: 'Derving',
  show_reps_waste: 'Laat de knop voor verlies en verspilling zien in de bestelling',
  giveaway_reason: 'Reden van verlies/ derving',
  countr_desktop_not_found: 'Countr Desktop niet gevonden',
  clear_search: 'Zoeken opschonen',
  recovery_transaction_complete: 'Transactie ({{ref}}) voltooid',
  activate_simple_tiles: 'Activeer eenvoudige product tegels',
  not_print_receipt_cash: 'Bon niet afdrukken bij contant',
  allow_negative_payments: 'Negatieve verkoop toestaan',
  negative_payments_warning:
    'Schakel alstublieft negatieve betaling in de instellingen (tabblad transacties) in voordat u dit doet.',
  enable_method: 'Schakel methode in',
  error_addon_groups:
    'Er was iets mis met dit product Addon Group, controleer dit in de backoffice.',
  billed_to: 'Gefactureerd aan',
  payment_due: 'Betalingstermijn',
  amount_due: 'Verschuldigd bedrag',
  total_price: 'Totale prijs',
  show_invoice: 'Toon factuur',
  hide_invoice: 'Factuur verbergen',
  show_ean_sku_receipt: 'Toon  EAN op de bon',
  show_simple_receipt: 'Eenvoudige bon afdrukken',
  not_tracked: 'Niet bijgehouden',
  kitchen_print: 'Keuken print',
  kitchen_delivery_print: 'Keuken Delivery print',
  syncing_transactions: 'Transacties synchroniseren...',
  syncing_refunds: 'Terugbetalingen synchroniseren...',
  barcode_customer: 'Gebruik barcodescanner om de klant te doorzoeken',
  customer_add_cart: 'Klant toegevoegd aan bestelling',
  transaction_not_sync_yet: 'De transactie was nog niet gesynchroniseerd met de server',
  intercom_error:
    'Kan de intercom-plug-in niet starten, kijk als u een extensie voor een advertentieblok heeft of probeer deze in Dashboard te gebruiken.',
  pay_before_open_error: 'U moet eerst "Open Kassa" afronden voordat u kunt verkopen',
  cash_in_not_open_day: 'U moet eerst "Open Kassa" afronden voordat',
  cash_in_greater_start:
    'Ongeldig bedrag. Er moet eerst geld bij om het bedrag van Start Shift te kunnen matchen',
  open_close_shift: 'Open & Close Shift',
  opening_shift: 'Opening shift...',
  closing_shift: 'Closing shift...',
  not_valid_input: 'Voer geldig cijfer in.',
  previous_start_amount: 'Verwacht bedrag',
  reason_mandatory_helper: '* Er is een verschil, vul de reden in.',
  printer_settings: 'Printerinstellingen',
  printer_ip: 'Printer IP',
  test_printers: 'Test printers',
  printers_settings_saved: 'Printers instellingen opgeslagen',
  error_shift_start_day: 'Shift start day not found',
  no_connection_payment:
    'Geen internetverbinding, zorg er alstublieft voor dat u een verbinding herstelt voordat u met pin betaalt.',
  show_order_bill: 'Geef de order/bestelling weer in de bestellingkop',
  split_orders_items: 'Gesplitste printopdrachten per artikel',
  split_orders_amount: 'Gesplitste printopdrachten per bedrag',
  partial_payment_print: 'Ontvangstbevestiging voor elke deelbetaling',
  minimum_age: 'Minimum leeftijdsverificatie',
  minimum_age_alert: 'Klant: {{customer}}. Zorg ervoor dat de klant ouder is dan {{age}} jaar',
  customer_required_title: 'Klant vereist',
  customer_required_text: 'Klant is vereist voor deze betaalmethode',
  show_pretax_receipt: 'Toon meer over het aantal belastingen',
  show_full_receipt_after_payment: 'Toon de volledige bon na betaling',
  new_version_title: 'Nieuwe versie beschikbaar',
  new_version_text: 'Nieuwe inhoud is beschikbaar, vernieuw alstublieft.',
  new_version_text_hint: 'U kunt de release-opmerkingen bekijken in het linkermenu.',
  reload: 'Herladen',
  release_note: 'Verbeteringen',
  play_sound_success_scan: 'Speel geluid af op gescande barcode',
  manual_sync_transactions: 'Handmatige synchronisatie transacties',
  manual_sync_transactions_question: 'Wilt u deze transacties handmatig synchroniseren?',
  improvement: 'VERBETERING',
  fixed: 'OPGELOST',
  recover_carts: 'Herstel karren',
  recover_carts_alert:
    'Weet u zeker dat u de bestelling naar dit apparaat wilt overbrengen? Nadat u de bestelling hebt overgedragen, wordt deze van het andere apparaat verwijderd.',
  recover_carts_complete:
    'Herstel karren voltooid. Nu kunt u het selecteren in popover van de selectie van het bestelling.',
  select_all_carts: 'Selecteer alle karren',
  payment_started: 'Betaling gestart',
  outstanding_inovices: 'U heeft openstaande facturen - ga naar uw dashboard om deze te combineren',
  quota_warning_title: 'Opslagquotum bereikt maximale capaciteit',
  quota_warning_text: 'U gebruikt {{percent}}% van de capaciteit.',
  quota_warning_text_hint: 'Probeer alsjeblieft je computerruimte vrij te maken.',
  welcome_to: 'Welkom bij',
  kassa_open: 'Beschikbaar kassa',
  kassa_closed: 'Gesloten kassa',
  kassa_closed_hint: 'Ga naar een ander kassa',
  enable_customer_screen: 'Schakel klantenscherm in',
  cds_settings: 'Klantweergave-instellingen',
  cds_port: 'Klantweergave poort',
  web_pos: 'Web POS',
  desktop_pos: 'Desktop POS',
  pos: 'App POS',
  no_internet_connection: 'U kunt deze actie niet uitvoeren. Geen internet verbinding.',
  storage: 'Opslagruimte',
  storage_data: 'Opslag gebruikt: {{quota}}%',
  extra_info_title: 'Voer extra artikelinformatie in',
  extra_info_sub_title: 'Giftcard of referentienummer',
  net: 'Netto',
  delivery_details: 'Verzendgegevens',
  delivery_time: 'Aflevertijd',
  pickup_time: 'Ophaal tijd',
  order_type: 'Bestel Type',
  order_courier: 'Bezorgd door',
  delivery_order_warning:
    'Dit is een leveringsorder van {{source}}, er kan geen nieuw product aan worden toegevoegd.',
  delivery_toast: 'Afleveropdracht ontvangen: {{by}} - {{receipt_id}}',
  delivery_requested: 'Levering gevraagd: {{message}}',
  tables_or_carts_desc: 'Toon de karrennamen als tabellen (bijvoorbeeld Kar 1 wordt Tabel 1).',
  cart_always_open_desc:
    'Houd de winkelwagen (items, totaal, belastingen, ...) open aan de rechterkant van het scherm.',
  show_product_image_desc: 'Toon de afbeelding van het product in de lijst met winkelwagenitems.',
  allow_cart_discounts_desc: 'Laat toe om korting te geven op de totale winkelwagen.',
  allow_product_discounts_desc: 'Toestaan ',
  enable_cart_item_remove_desc: 'Schakel de knop in om een ',
  show_calculator_desc: 'Toon numpad om het aantal producten te selecteren.',
  show_reps_waste_desc: 'Toon herhalingen en afvalknop in de kop van de winkelwagen.',
  barcode_customer_desc:
    'Sta de barcodescanner toe om de klanttoken te scannen en voeg de klant toe aan de winkelwagen.',
  show_stock_level_desc: 'Toon de voorraadeenheden van het product in de producttegel.',
  show_price_desc: 'Toon de prijs van het product in de producttegel.',
  barcode_scanner_desc: 'Schakel barcodescanner in om een ',
  play_sound_success_scan_desc:
    'Speel geluid af wanneer een product met succes is gescand en aan de winkelwagen is toegevoegd.',
  show_product_image_list_desc: 'Toon de afbeelding van het product in de lijst met producten.',
  activate_simple_tiles_desc:
    'Verwijder animaties en effecten van producttegels (aanbevolen voor oude computers).',
  product_tile_size_desc: 'Dubbele tegelgrootte van het product',
  hide_categories_desc:
    'Verberg de lijst met categorieën en voeg een modaal toe om deze te selecteren.',
  categories_alphabetic_order_desc: 'Lijst met categorieën weergeven in alfabetische volgorde.',
  products_alphabetic_order_desc: 'Toon productenlijst in alfabetische volgorde.',
  skip_payment_success_info_desc:
    'Dialoogvenster betalingssucces niet weergeven (transactie hervatten) na een succesvolle betaling.',
  allow_partial_payment_desc: 'Gedeeltelijke betaling toestaan ',
  allow_negative_payments_desc: 'Toestaan ',
  allow_qrcode_scanner_desc: 'Toon qrcode-lezer in de kop van de winkelwagen.',
  show_pretax_receipt_desc: 'Toon het bedrag vóór belasting op de bon.',
  show_full_receipt_after_payment_desc:
    'Toon volledige bon in de transactie-cv na succesvolle betaling.',
  refund_same_method_desc: 'Schakel in om een ',
  enable_customer_screen_desc:
    'Klantschermdetails inschakelen (hardware voor klantweergave nodig).',
  kiosk_mode_desc: 'Kiosk-modus inschakelen.',
  admin_pin_desc:
    'Schakel de beheerderspincode in (om de kioskmodus te vergrendelen/ontgrendelen).',
  employee_pin_desc: 'Maak selectie van medewerkers mogelijk.',
  employee_lock_desc:
    'Als de werknemerspincode is ingeschakeld, kunt u de POS na elke transactie vergrendelen en moet u een nieuwe werknemer selecteren om deze te blijven gebruiken.',
  open_close_shift_desc: 'Schakel de functie Open/Close shift in.',
  reports_at_store_level_desc:
    'Rapporten weergeven op locatieniveau (alle transacties van alle apparaten tellend).',
  language_desc: 'Wijzig de POS-taal.',
  kitchen_print_desc: 'Afdrukopdracht na succesvolle transactie.',
  kitchen_delivery_print_desc:
    'Print bestelling na ontvangst van een transactie succes via leveringssysteem.',
  show_order_bill_desc: 'Toon printopdracht en factuur in de kop van de winkelwagen.',
  split_orders_items_desc: 'Printopdracht splitsen in items.',
  split_orders_amount_desc: 'Printopdracht splitsen in aantal.',
  partial_payment_print_desc: 'Gedeeltelijke bon afdrukken na elke gedeeltelijke betaling.',
  show_ean_sku_receipt_desc: 'Toon EAN/SKU in de bon.',
  a4_paper_desc: 'Schakel A4-afdrukken in.',
  show_carts_list_alphabetic: 'Lijst met winkelwagens in alfabetische volgorde weergeven',
  show_carts_list_alphabetic_desc: 'Toon winkelwagenlijst in alfabetische volgorde.',
  show_cart_name_transaction: 'Toon winkelwagennaam in ontvangstbewijs',
  show_cart_name_transaction_desc:
    'Toon de naam van het winkelwagentje in de bon (transactielijst of transactie voltooid).',
  open_kds: 'KDS',
  open_chat: 'Countr Chat',
  partial_payment_bigger_zero: 'Gedeeltelijke betalingswaarde moet groter zijn dan 0',
  allow_product_exchanges: 'Productuitwisseling toestaan',
  allow_product_exchanges_desc:
    'Voeg producten toe die de klant wil ruilen en voeg het als negatief bedrag toe aan de bestelling',
  exchangeable_items: 'Verwisselbare artikelen',
  to_be_exchangeable: 'Uitwisselbaar zijn',
  exchange_source: 'Uitwisselingsbron',
  inventory_not_found: 'Productvoorraad niet gevonden',
  drop_ship: 'Drop-Ship',
  enable_drop_ship: 'Drop-shipping inschakelen',
  enable_drop_ship_desc: 'Drop-shipping-functie inschakelen.',
  drop_ship_warning: 'Drop-Ship - Geen klant geselecteerd',
  cart_as_tables_not_available:
    'bestelling als tabelfunctie heeft geen effect met de extensie Tafelindeling.',
  enable_deposit_button: 'Stortingsknop inschakelen',
  enable_deposit_button_desc: 'Stortingsknop inschakelen in de sub-subkar van het bestelling',
  allow_voucher_scan_desc:
    'Gebruik de barcodescanner om naar kortingsbonnen te zoeken en deze toe te passen.',
  remove_customer: 'Klant verwijderen',
  remove_customer_question: 'Wilt u de klant uit deze bestelling verwijderen?',
  search_product_barcode_server: 'Zoek de barcode van het product in de server',
  search_product_barcode_server_desc:
    'Zoek de barcode van het product op de server als deze niet lokaal wordt gevonden.',
  show_floorplan_after_sale_desc:
    'Toon plattegrondindeling na elke verkoop in plaats van productlijst te tonen',
  ccv_settings: 'CCVinstellingen',
  ask_no_covers_or_takeaway_desc: 'Vraag om tafelschikking',
  send_order_kds: 'Bestelling verzenden naar KDS',
  mollie: 'Mollie',
  paynl: 'PayNL',
  transactionFee: 'Transactiekosten',
  pay_later_note_partial:
    '* Het is slechts mogelijk om 1 betaling achteraf per transactie te betalen.',
  no_print_delivery: 'Geen leveringsinfo voor deze bestelling.',
  load_linked_cart: 'Klik op de tabel om de gelinkte informatie te laden.',
  show_item_status: 'Toon itemstatus bij invoer van bestelling',
  show_item_status_desc:
    'Toon een markeringsrand aan de linkerkant van het bestelling met de huidige status van dit item.',
  access_denied: 'Toegang geweigerd! Probeer in te loggen met een Master-account.',
  current_floor: 'Huidige verdieping',
  total_to_pay: 'In totaal te betalen: {{currency}} {{amount}}',
  total_amount_paid: 'Totaal Betaald: {{currency}} {{amount}}',
  total_remaining: 'Totaal resterend: {{currency}} {{amount}}',
  redeem: 'Inwisselen',
  current_balance: 'Huidig saldo',
  card_activity: 'Kaartactiviteit',
  scan_fail: 'Zoeken naar gescande code mislukt',
  disallow_offline: 'POS kan niet offline werken',
  disallow_offline_fiscal: 'POS kan vanwege de fiscale wetgeving niet offline werken.',
  fiscal_print_error: 'Kan geen kassabon afdrukken zonder fiscale informatie',
  enable_giftcard_scan: 'Schakel cadeaukaartscan in',
  enable_giftcard_scan_desc:
    'Schakel de functie in om de cadeaubon te scannen en het saldo ervan te verminderen.',
  print_qr: 'Druk de QR-code af op de bon',
  print_qr_desc: 'Schakel in om de QR-code onder aan de bon af te drukken.',
  theme: 'Thema',
  theme_desc:
    'Schakel het standaardthema over naar een thema dat u leuk vindt (de themalijst wordt per locatie verstrekt).',
  invalid_addon_groups:
    'Er is iets mis met de add-ongroepen van het product. Ga naar uw dashboard en controleer het voordat u het gebruikt.',
  products_not_found: 'Producten niet gevonden',
  show_suggestion_amounts: 'Toon knoppen voor suggestiebedrag',
  show_suggestion_amounts_desc: 'Toon/verberg knoppen voor suggestiebedrag in betalingsmodaliteit.',
  save_table: 'Opslaan',
  save_cart: 'Opslaan',
  enable_groups: 'Groepsbestellingsitems',
  highlight_items: 'Markeer items',
  set_ready_print: 'Instellen als Gereed en afdrukken',
  start_preparing: 'Begin met de voorbereiding',
  group_qty: 'Groepshoeveelheid',
  show_more: 'Toon meer informatie',
  notes_per_qty: 'Opmerkingen per aantal',
  print_online_order_receipts: 'Online bestelling bon automatisch afdrukken',
  select_items: 'Selecteer artikelen',
  partial_payment_not_allowed_by_value: 'Gedeeltelijke betalingen zijn niet toegestaan',
  product_media: 'Media',
  show_product_media: 'Media laten zien',
  change_seat: 'Gast kiezen',
  add_seat: 'Gast toevoegen',
  continue_partial_payment: 'Nog een gedeelte betaling?',
  seat: 'Gast',
  partial_payment_seat: 'Per gast',
  processing: 'In behandeling',
  groupOrderPrintBySeat: 'Groepeer het printen van bestellingen op gast',
  fiscal_error: 'Fout met fiscale data module',
  switch_employee: 'Werknemer wisselen',
  no_order_removed: 'Niet bestelde producten verwijderd',
  connected_device: 'Verbonden apparaat',
  deleted: 'verwijderd',
  ready_pickup: 'klaar om af te halen',
  employee_pin_changed: 'De pincode van de werknemer is gewijzigd',
  order_kds_no_printer_msg:
    'Bestelling verzonden naar KDS maar niet naar printer, printerapparaat instellen om de bestelling te verzenden',
  order_kds_printer_msg: 'Bestelling verzonden naar KDS en naar printer',
  add_table: 'toegevoegd aan tabel',
  product_add_cart: 'Product toegevoegd aan bestelling',
  product_ordered: 'Product beteld',
  product_not_ordered: 'Product niet besteld',
  save_guests: 'Bewaar gasten',
  guests_num: 'Aantal gasten',
  add_guest_num: 'Aantal gasten toevoegen',
  no_stores_created: 'Geen locaties gemaakt',
  device_print_msg: 'Het geselecteerde apparaat is verantwoordelijk voor het afdrukken van de bon',
  prep_time: 'Voorbereidingstijd',
  no_tables_store: 'Geen tafels voor deze locatie',
  go_back: 'Ga terug!',
  remove_not_order_msg: "Weet je zeker dat je de 'Niet bestelde' items wilt verwijderen van",
  order_not_sent_back: 'Bestelling is niet verzonden naar KDS! Weet je zeker dat je terug wilt?',
  no_available_addons_for_products: 'Geen beschikbare add-ons voor het geselecteerde product.',
  no_logged_employee: 'Er is geen medewerker ingelogd!',
  empty_tables: 'Lege tafels',
  close_search: 'Zoekbalk sluiten',
  no_empty_tables: 'Er zijn geen lege tafels!',
  my_tables: 'Mijn tafels',
  current_device: 'Huidig apparaat',
  employee_info: 'Gegevens van de werknemer',
  show_cart: 'bestelling tonen',
  show_seat_separator: 'Stoelscheider weergeven',
  set_print_device: 'Printapparaat instellen',
  table_search: 'Tafel zoeken',
  order_by_seat: 'Per stoel bestellen',
  table_list_view: 'Tafels als lijst weergeven',
  customer_groups: 'Klantgroepen',
  sales_insights: 'Verkoopinzichten',
  product_insights: 'Productinzichten',
  store_insights: 'Locatieinzichten',
  allow_ticket_scan: 'Tickets scannen met barcode scanner',
  no_pin_employee: 'Medewerker zonder pincode',
  allow_ticket_scan_desc: 'Gebruik de barcodescanner om tickets te zoeken.',
  admin_only_custom_item: 'Aangepast item alleen inschakelen voor beheerder',
  admin_only_custom_item_desc:
    'Alleen gebruikers met beheerdersrechten kunnen een aangepast item maken. U moet ook de werknemerspincode activeren.',
  mandatory_employee_pin: 'Verplichte activering van de werknemerspincode.',
  admin_only_refunds: 'Alleen beheerders kunnen restituties uitvoeren',
  admin_only_refunds_desc:
    'Alleen beheerders kunnen restituties uitvoeren. U moet ook de werknemerspincode activeren.',
  round_cash_transactions_desc:
    'Contante betalingen automatisch afronden naar de dichtstbijzijnde 0,05',
  unlimited_usage: 'Onbeperkt gebruik',
  infinite: 'Onbeperkt',
  disable_custom_discounts: 'Aangepaste kortingen uitschakelen',
  disable_custom_discounts_desc:
    'Schakel aangepaste kortingen uit, maar sta vooraf gedefinieerde kortingen toe.',
  guest: 'Gast',
  select_method: 'Selecteer methode',
  manual_insert: 'Handmatige invoer',
  enter_code: 'Voer code in',
  existing_by_value_payment: '* Je kunt betalen per waarde en per stoel en/of item niet mixen',
  order_sent: 'Bestelling Verzonden',
  select_multiple_seats: 'Selecteer meerdere stoelen',
  change_seat_or_guest_num: 'Stoel- of gastnummer wijzigen',
  change_guest_num: 'Wijzig gastnummer',
  giftcard_low_value:
    '* Het saldo van de cadeaukaart is lager dan de waarde van het geselecteerde item. Probeer in plaats daarvan op waarde te betalen.',
  order_by_seat_desc: 'Schakel de functie voor bestellen per stoel in.',
  legacy_images: 'Productbeeld afdrukken op oude formaat',
  received_amount: 'Ontvangen bedrag',
  order_products_action: 'Producten bestellen',
  ordered_products: 'Bestelde producten',
  select_table: 'Selecteer tafel',
  ordered: 'Besteld',
  scan_or_type: 'Scan of typ',
  type_code: 'Typ code',
  number_extension: 'Nummerextensie',
  move_processing_ticket_first: 'Verplaats verwerkingstickets eerst',
  disable_adblocker: 'Schakel uw AdBlocker uit en ververs de pagina',
  remove_partial_payment_by_item:
    'Gedeeltelijke betaling per item gedetecteerd! Verwijder het om door te gaan met deze actie!',
  add_nutrients: 'Voeg voedingsstoffen toe',
  giftcard_warning_msg:
    'De standaard geldigheid van een giftcard is 2 jaar. Weet je zeker dat je dit wilt verlagen?',
  edit_devicess_main_details: 'Bewerk de belangrijkste details van apparaten.',
  create_custom_payment_methods_for_your_device: 'Maak aangepaste betaalmethoden voor uw apparaat.',
  show_open_carts_from_device: 'Openstaande bestellingen van apparaat weergeven',
  create_discounts_for_your_stores: 'Creëer kortingen voor uw locatie.',
  create_reps_waste_for_your_stores: 'Creëer herhalingen en afval voor uw locatie.',
  show_cat_bar: 'Toon categoriebalk',
  bulk_update: 'Items in bulk bijwerken',
  show_category_product_info: 'Toon categorie-info in product',
  cart_will_empty: 'Het winkelwagentje wordt automatisch leeggemaakt',
  seconds: 'seconden',
  corrupted_addons: 'Product heeft beschadigde add-ons. Kijk dan in de Backoffice.',
  add_more_items: 'Meer artikelen toevoegen',
  paying_with: 'Betalen met {{method}}',
  screen_saver_delay: 'Screensaver vertraging (seconden)',
  screen_saver: 'Schermbeveiliging',
  enable_screen_saver: 'Schermbeveiliging inschakelen',
  no_screen_saver: 'Kan schermbeveiliging niet detecteren',
  screen_saver_info: 'Screensaver-info',
  type: 'Type',
  running_payment: 'Bezig',
  print_brand: 'Merknaam op bon afdrukken',
  show_product_options_long_press: 'Lang indrukken om productopties weer te geven',
  asap: 'ZSM',
  order_source: 'Bestellingsbron',
  ready_items: 'Klaar items',
  disabled: 'Uit',
  ready_orders: 'Klaar bestellingen',
  admin_only: 'Alleen beschikbaar voor beheerders',
  ask_buzzer_info: 'Coaster informatie verplicht',
  ask_buzzer_info_desc:
    'Vereisen invoer van coaster voor elke bestelling - b.v. coaster nummer of ID',
  buzzer: 'Coaster',
  print_tickets: 'Ticketbarcodes afdrukken op bon',
  print_tickets_desc:
    'Druk streepjescodes af voor alle tickets die in de bon op de bon zelf worden gevonden',
  show_employee_on_product: 'Laat zien welke medewerker elk item heeft toegevoegd',
  surcharge: 'Toeslag',
  changes_list: 'Lijst met wijzigingen',
  action_type: 'Actie type',
  previous_change: 'Vorige wijziging',
  new_changes: 'Nieuwe wijzigingen',
  removed_fields: 'Verwijderde velden',
  updated_fields: 'Bijgewerkte velden',
  full_history: 'Volledige geschiedenis',
  changes_history: 'Wijzigingsgeschiedenis',
  product_history: 'Productgeschiedenis',
  category_history: 'Categorie geschiedenis',
  device_history: 'Apparaatgeschiedenis',
  enable_mandatory_customer_address: 'Verplicht adres van de klant',
  please_fill_all_fields:
    'Vul alle verplichte velden in: gebouwnummer, straat, stad, staat/provincie, postcode, land, voor- en achternaam, e-mailadres of telefoonnummer',
  show_product_price_cat: 'Toon productprijs en categorie',
  open_cash_drawer_giftcard: 'Lade automatisch openen voor Countr cadeaubon',
  generate_barcode: 'Barcode genereren',
  select_file: 'Selecteer bestand',
  drop_file: 'Bestand neerzetten',
  create_media: 'Media maken voor',
  upload_image: 'Afbeelding uploaden',
  upload_file: 'Upload bestand',
  add_custom_img: 'Voeg uw aangepaste afbeelding toe',
  select_media: 'Selecteer mediatype',
  external_url_iframe: 'select_ticket_print',
  select_ticket_print: 'Selecteer type om af te drukken',
  external_service_provider: 'Externe dienstverlener',
  barcode_input: 'Barcode-invoer',
  stock_load_msg:
    'Controleer alle producten op voorraad. Dit kan wat tijd kosten. Wacht alsjeblieft!',
  no_available_stores: 'Geen winkels beschikbaar.',
  buy_combination_get_deal_new_price: 'Combineer artikelen voor een contractprijs',
  discount_specific_products_categories: 'Korting op specifieke producten of categorieën',
  discount_all_prodcucts: 'Korting op alle producten',
  buy_x_items_get_cheapest_discount: 'Koop x items, krijg de goedkoopste met korting',
  spend_certain_amount_get_discount: 'Besteed een bepaald bedrag, krijg korting',
  customer_group_item_discount: 'Korting op specifieke klantgroepen',
  item_surcharge: 'Toeslag op specifieke producten, categorieën of klantgroepen',
  cart_surcharge: 'Toeslag op de gehele bestelling',
  import: 'Importeren',
  export: 'Exporteren',
  simple: 'Eenvoudig',
  advanced: 'Geavanceerd',
  delete_import: 'Producten importeren verwijderen',
  upload_csv: 'Upload uw CSV-bestand',
  open_file: 'Open het bestand met',
  save_file: 'Sla het bestand op als .csv',
  value_remaining: 'Resterende waarde:',
  qr_codes_for_line_items: 'QR-code afdrukken in plaats van streepjescode voor regelitems',
  barcode_prefix: 'Streepjescodevoorvoegsel',
  barcode_digits: 'Barcode lengte / cijfers',
  external_identifier: 'Externe identificatie',
  cost_centre: 'Kostenplaats',
  shared_shift: 'Deel shift',
  shared_shift_desc: 'Van werknemer wisselen hoeft het register niet opnieuw te openen',
  empty_cart_voucher_warning: 'Producten toevoegen om de voucher te gebruiken!',
  ask_for_help: 'Vraag om hulp',
  help_is_coming: 'Er komt hulp',
  you_paid: 'U heeft betaald.',
  end_msg: 'Bedankt en tot ziens.',
  scan_products: 'Scan uw product om te beginnen',
  welcome_valk: 'Welkom in Valk',
  solution_checkout: 'Oplossingen Afrekenen!',
  basket_check_complete: 'Status winkelwagentje controleren',
  start_scanning: 'Scan uw product a.u.b.',
  close_auto: 'Sluit automatisch in',
  open_close_employee: 'Open & Sluit per medewerker',
  print_table_name: 'Tafel naam afdrukken',
  print_note: 'Notitie afdrukken',
  reset_state: 'Applicatiestatus resetten',
  sample_check_msg_top: 'Ogenblik aub.',
  sample_check_msg_bottom: 'Er is een medewerker onderweg',
  disallow_close_register_open_tables: 'Sluit POS niet als bestellingen nog openstaan',
  opening_amount: 'Openingsbedrag',
  cash_payments: 'Contante betalingen',
  msg_nix: 'Nix18-product toegevoegd, er komt zo snel mogelijk een medewerker',
  receipt_type: 'Kies het type ontvangstbewijs',
  full_receipt: 'Volledige bon',
  short_receipt: 'Korte bon',
  start: 'START',
  go_to_cart: 'Naar winkelwagen',
  full_basket_check: 'Volledige winkelwagen check',
  missing_items_amount: 'Aantal ontbrekende items',
  extra_items_scanned: 'Aantal extra items',
  finish_check: 'Controle voltooien',
  original_items: 'Originele items',
  checked_items: 'Aangevinkte items',
  no_receipt: 'Geen bon',
  refresh_categories: 'Vernieuwen categorieen',
  pay_img_text_up: 'Betalen met pin, <br /> volg instructies op pinapparaat links',
  pay_img_text_down: 'Na betaling volgt de kassabon',
  old: 'Oud',
  new: 'Nieuw',
  calculating: 'Berekenen',
  merging: 'Bezig met samenvoegen',
  select_table_from_list:
    'Selecteer een tabel uit de lijst om alle andere geselecteerde tabellen samen te voegen',
  cant_merge_same:
    'Kan dezelfde tabel niet samenvoegen, selecteer een andere tabel om samen te voegen',
  remove_from_table: 'van tafel verwijderen',
  login_then_reboot: 'Gelieve eerst in te loggen en daarna de applicatie opnieuw te starten',
  eat_in: 'Hier opeten',
  preparing_ods: 'Wordt bereid',
  ready_ods: 'Gereed',
  cancel_order_pin: 'PIN om bestelling te annuleren',
  sms_order_ready: 'U ontvangt een SMS wanneer uw bestelling klaar staat',
  cancel_order: 'Bestelling annuleren',
  locked_pos: 'POS is vergrendeld',
  fiscal_store_employee: 'Fiscale winkels moeten een kassamedewerker hebben om een ',
  invalid_employee: 'Ongeldige werknemer',
  update_employee_ssn: 'Werk het sofinummer van de werknemer bij',
  social_security_number: 'Burgerservicenummer',
  fiscal_one_employee: 'U moet minimaal één medewerker hebben ingeschreven bij een fiscale winkel',
  fiscal_employee_ssn:
    'Werknemers die zich inschrijven bij fiscale winkels moeten een geldig burgerservicenummer hebben',
  employee_unable_delete:
    'Kan werknemer niet verwijderen omdat hij is gekoppeld in een fiscale winkel. Gelieve de medewerker eerst uit de winkel te verwijderen.',
  vat_receipt: 'BTW-KASTICKET',
  not_vat_receipt: 'DIT IS GEEN GELDIG BTW-KASTICKET',
  deposit_error: 'Stortingsfout',
  deposit_error_message:
    'Op een product met een positieve prijs kunt u geen negatieve aanbetaling hebben. Alleen producten met prijs 0 of minder kunnen een negatieve aanbetaling hebben.',
  assign_printers_to_your_location: 'Printers toewijzen aan uw locatie',
  save_new_printer: 'Nieuwe printer opslaan',
  update_printer: 'Printer bijwerken',
  location_no_saved_printers: 'Er zijn geen opgeslagen printers voor deze locatie',
  work_in: 'Arbeid In',
  work_out: 'Arbeid Uit',
  sort_items_by_action: 'Sorteer items op actie (voorbereiden, nieuw, klaar)',
  xreport: 'X-rapport',
  zreport: 'Z-rapport',
  xuserreport: 'X-gebruiker',
  zuserreport: 'Z-gebruiker',
  rounding: 'Afronding',
  fiscal_settings: 'Fiscale instellingen',
  pos_serial_num: 'Kassa productienummer',
  print_x_report: 'X-rapport afdrukken',
  shox_x_report: 'Toon X-rapport',
  print_z_report: 'Z-rapport afdrukken',
  show_z_report: 'Z-rapport tonen',
  unfiscalised_transactions_report_incorrect:
    '{{transaction_number}} transactie(s) niet belast\nRapporten zullen hierdoor niet de meest actuele status weergeven',
  zip_mandatory: 'Postcode vereist',
  number_mandatory: 'Gebouwnummer vereist',
  multiple_options_found: 'Meerdere opties gevonden',
  print_card_info: 'Kaartbetalingsgegevens afdrukken',
  second_screen: 'Secundair display beschikbaar',
  cashdrawer_partial_payment: 'Kassalade openen bij gedeeltelijke betaling',
  right: 'Rechts',
  left: 'Links',
  top: 'Bovenkant',
  bottom: 'Onderkant',
  refund_print: 'Refund',
  rounding_print: 'Rounding',
  print_vat_name: 'Druk de btw-naam per artikel af op de bon',
  proforma_ticket: 'Pro-Forma Ticket',
  receipt_already_printed: 'Bon is al geprint',
  allow_product_refunds: 'Laat productrestituties toe',
  allow_product_refunds_desc: 'Maakt de terugbetaling van producten mogelijk',
  shift_per_store: 'Kassa openen / sluiten per winkel',
  shift_per_store_desc: 'Shift zal blijvend zijn op alle winkelapparaten',
  create_custom_loyalty_methods_for_your_store: 'Loyaliteitsmethoden toevoegen voor uw locatie.',
  cannot_combine_positive_and_negative:
    'Er kunnen niet zowel positieve als negatieve prijzen op dezelfde bon staan',
  list_horizontal: 'Geef bestellingen horizontaal weer',
  opened_delivery_notes: 'Openstaande leveringsbonnen',
  payment_on_account: 'Betaal op rekening',
  receipt_id: 'Bon-ID',
  invoice_number: 'Factuurnummer',
  customer_id: 'Klanten ID',
  delivery_notes: 'Pakbonnen',
  delivery_note_info: 'Leveringsnota-info',
  download_invoice: 'Factuur downloaden',
  invoice_info: 'Factuurinformatie',
  total_number: 'Totaal aantal',
  create_invoice: 'Factuur maken',
  rows_per_page: 'Rijen per pagina',
  plus_deposit_of: 'Prijs plus statiegeld van',
  add_note_to_item: 'Voeg een notitie toe aan het item',
  round_electronic_transactions: 'Rond elektronische betalingen af naar de dichtstbijzijnde 0,05',
  maximum_amount_single_transaction_excedeed: 'Transactie overschrijdt de maximale bedraglimiet',
  cannot_process_voucher_after_other_methods:
    'Kan niet-restitueerbare betalingsmethoden niet verwerken na andere betalingen',
  all_giftcards_vouchers_processed: 'Zijn alle cadeaubonnen en/of vouchers verwerkt?',
  all_cash_processed: 'Zijn alle contante betalingen verwerkt?',
  disable_print_removed_items: 'Schakel automatisch afdrukken van verwijderde items uit',
  disable_print_removed_items_desc:
    'Schakel het automatisch afdrukken van verwijderde items uit - b.v. artikel geannuleerd',
  note_too_long: 'Memo is te lang',
  one_ticket_per_cart: 'Er is slechts één ticket per winkelwagen toegestaan',
  no_timeslots_available: 'Geen tijdslots beschikbaar',
  download_paynl_app: 'Download de Pay. SoftPOS App',
  paynlsoftpos: 'Pay. SoftPOS',
  store_background: 'Achtergrond afbeelding',
  disable_print_cache: 'Schakel het cachen van mislukte afdrukitems uit',
  delivery_fees: 'Bezorgkosten',
  keep_single_cart: 'Bewaar één winkelwagentje per apparaat',
  cash_drawer_admin_only: 'Enkel beheerders kunnen de kasopenen',
  cash_drawer_admin_only_desc:
    'Medewerkers zonder administratieve rechten zullen de kas niet kunnen openen'
}

export default nlLang
